import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { checkIfPlanEditor } from 'src/app/private/pages/plans-page/plans-page-helpers';

@Component({
  selector: 'app-plans-subnav',
  templateUrl: './plans-subnav.component.html',
})
export class PlansSubNavComponent {
  user: User;

  isPlanEditor = false;

  canSeeStatusReports = false;

  @Input() planId: number;

  constructor(private store: Store) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    if (this.user) {
      this.isPlanEditor = checkIfPlanEditor(this.user);
      this.canSeeStatusReports =
        this.isPlanEditor || this.user.roles.includes(UserRole.DISTRICT_ADMIN);
    }
  }
}
