import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PresenceChannel } from 'pusher-js';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  getTimezoneLinkBackFromTimezone,
  TimezoneLinkBack,
} from 'src/app/common/utilities/time-helpers';

import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { booleanFromBooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import {
  AttendeeRubricDTO,
  RubricDTO,
  StrandDTO,
} from '../../dtos/attendee-rubric.dto';
import { BadgeUserDTO } from '../../dtos/badge.dto';
import {
  CoachingSessionDTO,
  CoachingSessionTypeDTO,
  NewCoachingSessionItemsPayloadDTO,
  NextSessionDTO,
} from '../../dtos/coaching-session.dto';
import { FeedbackDTO } from '../../dtos/feedback.dto';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { FeedbackPayload } from '../../types/payloads/coaching-log.payloads';
import { DatepickerOutput } from '../datepicker/datepicker.component';

@Component({
  selector: 'app-coaching-session-summary',
  templateUrl: './coaching-session-summary.component.html',
  styleUrls: ['./coaching-session-summary.component.scss'],
})
export class CoachingSessionSummaryComponent implements OnInit, OnDestroy {
  @Input() session: CoachingSessionDTO;

  @Input() rubricData: AttendeeRubricDTO[];

  user$ = select(UserState.getUser);

  user: User;

  badgeUsers: BadgeUserDTO[] = [];

  feedback: FeedbackDTO[] = [];

  feedbackForm: FormGroup;

  feedbackFormSubmitted = false;

  feedbackFormSubmitLoading = false;

  isEditingFeedback = false;

  editFeedbackForm: FormGroup;

  editFeedbackFormSubmitted = false;

  editFeedbackFormSubmitLoading = false;

  nextSessionForm: FormGroup;

  nextSessionFormSubmitted = false;

  nextSessionFormSubmitLoading = false;

  tomorrowsDate = DateTime.now().plus({ days: 1 }).toJSDate();

  tomorrowsDateTime = DateTime.now().plus({ days: 1 }).toSeconds();

  currentIcon: string;

  pusherChannel: PresenceChannel;

  coachlogItemsPayload: NewCoachingSessionItemsPayloadDTO = {
    title: '',
    logType: null,
    startDatetime: 0,
    endDatetime: 0,
    timezone: TimezoneLinkBack.Central,
    coachUser: null,
    creatorUserId: 0,
    coachees: [],
    shadowers: [],
    is_private: false,
    from_coachlog_id: 0,
  };

  duration = 60;

  defaultLogType: CoachingSessionTypeDTO;

  defaultTimezone: TimezoneLinkBack = TimezoneLinkBack.Central;

  formValid = true;

  scheduledSession: NextSessionDTO;

  ratings = [1, 2, 3, 4];

  confidence = [1, 2, 3, 4];

  selectedSessionRating: number | null = null;

  selectedSessionConfidence: number | null = null;

  subs: Subscription[] = [];

  constructor(
    private coachingLogService: CoachingLogService,
    private formBuilder: FormBuilder,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  getAttendeeBadgeUser(attendeeUser: UserDTO): BadgeUserDTO | undefined {
    if (this.badgeUsers) {
      return this.badgeUsers.filter(
        (badgeUser) => badgeUser.id === attendeeUser.id
      )[0];
    }
    return undefined;
  }

  getAttendeeRubrics(userId: number): RubricDTO[] | undefined {
    if (this.rubricData) {
      return this.rubricData.filter(
        (rubricData) => rubricData.userId === userId
      )[0].rubrics;
    }
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getStrandProgress(strand: StrandDTO): number {
    let levelsCompleted = 0;
    if (strand.level1.levelCompleted) {
      levelsCompleted = 1;
    }
    if (strand.level2.levelCompleted) {
      levelsCompleted = 2;
    }
    if (strand.level3.levelCompleted) {
      levelsCompleted = 3;
    }
    if (strand.level4.levelCompleted) {
      levelsCompleted = 4;
    }
    return levelsCompleted;
  }

  // eslint-disable-next-line class-methods-use-this
  getLevels(strand: StrandDTO) {
    return [strand.level1, strand.level2, strand.level3, strand.level4];
  }

  ngOnInit() {
    this.getBadgeUsers();
    this.getFeedback();
    this.getNextSession();
    this.getSessionRating();
    this.getSessionConfidence();

    this.pusherChannel = this.coachingLogService.getChannel(
      this.session.id
    ) as PresenceChannel;

    this.pusherChannel.bind('coachlog:session_created', () => {
      this.getNextSession();
    });

    this.feedbackForm = this.formBuilder.group({
      feedback: ['', Validators.required],
    });
    this.nextSessionForm = this.formBuilder.group({
      title: [this.session.title, Validators.required],
    });

    this.defaultLogType = this.session.type;
    this.updateSessionLogType(this.defaultLogType);
    const defaultTimezone = getTimezoneLinkBackFromTimezone(
      this.session.timezone
    );
    if (defaultTimezone) {
      this.defaultTimezone = defaultTimezone;
    }
  }

  updateSessionLogType(logType: CoachingSessionTypeDTO | null) {
    this.coachlogItemsPayload.logType = logType;
  }

  updateDateTime(output: DatepickerOutput[]) {
    this.coachlogItemsPayload.startDatetime = output[0].time / 1000;
    this.coachlogItemsPayload.endDatetime = output[1].time / 1000;
    this.coachlogItemsPayload.timezone = output[0].timezone;
    this.coachingLogService.updateCoachingLog(
      this.session.id,
      this.coachlogItemsPayload
    );
  }

  getFeedback() {
    this.coachingLogService.getFeedback(this.session.id).subscribe((res) => {
      this.feedback = res.filter((item) => item.userId === this.user.id);
    });
  }

  getBadgeUsers() {
    this.coachingLogService.getBadgeUsers(this.session.id).subscribe((res) => {
      this.badgeUsers = res;
    });
  }

  getNextSession() {
    this.coachingLogService
      .getAttendeesNeedNextLog(this.session.id)
      .subscribe((res) => {
        if (res.attendeesScheduled[0]) {
          this.scheduledSession = res.attendeesScheduled[0].nextLog;
        }
      });
  }

  getSessionRating() {
    this.session.attendees.forEach((attendee) => {
      if (attendee.userId === this.user.id) {
        this.selectedSessionRating = attendee.rating;
      }
    });
  }

  getSessionConfidence() {
    this.session.attendees.forEach((attendee) => {
      if (attendee.userId === this.user.id) {
        this.selectedSessionConfidence = attendee.self_rating;
      }
    });
  }

  submitFeedback() {
    this.feedbackFormSubmitted = true;

    this.isEditingFeedback = false;

    if (this.feedbackForm.invalid) {
      return;
    }

    this.feedbackFormSubmitLoading = true;

    const payload: FeedbackPayload = {
      content: this.feedbackForm.controls['feedback'].value,
      egrowe_coachlog_id: this.session.id,
      user_id: this.user?.id || 0,
    };

    this.coachingLogService.submitFeedback(payload).subscribe((res) => {
      if (res) {
        this.getFeedback();
        this.feedbackFormSubmitLoading = false;
      }
    });
  }

  editFeedback(itemId: number) {
    this.editFeedbackFormSubmitted = true;

    if (this.editFeedbackForm.invalid) {
      return;
    }

    this.editFeedbackFormSubmitLoading = true;

    this.coachingLogService
      .editFeedback(itemId, this.editFeedbackForm.controls['feedback'].value)
      .subscribe((res) => {
        if (res) {
          this.getFeedback();
          setTimeout(() => {
            this.editFeedbackFormSubmitLoading = false;
            this.isEditingFeedback = false;
          }, 250);
        }
      });
  }

  deleteFeedback(itemId: number) {
    this.coachingLogService.deleteFeedback(itemId).subscribe((res) => {
      if (res) {
        this.getFeedback();
        this.feedbackForm = this.formBuilder.group({
          feedback: ['', Validators.required],
        });
        this.feedbackFormSubmitted = false;
      }
    });
  }

  toggleEditMode(content: string) {
    this.isEditingFeedback = !this.isEditingFeedback;
    if (this.isEditingFeedback) {
      this.editFeedbackForm = this.formBuilder.group({
        feedback: [content, Validators.required],
      });
    }
  }

  scheduleNextSession() {
    this.coachlogItemsPayload = {
      ...this.coachlogItemsPayload,
      title: this.nextSessionForm.controls['title'].value,
      coachUser: this.user,
      creatorUserId: this.user?.id ? this.user.id : 0,
      coachees: this.session.attendees.map((attendee) => ({
        id: attendee.user.id,
        profile: attendee.user.profile,
      })),
      shadowers: this.session.shadowers.map((shadower) => ({
        id: shadower.user.id,
        profile: shadower.user.profile,
      })),
      is_private: booleanFromBooleanNumber(this.session.isPrivate),
      from_coachlog_id: this.session.id,
    };

    this.formValid = true;

    if (!this.coachlogItemsPayload.logType) {
      this.formValid = false;
    }

    this.nextSessionFormSubmitted = true;
    this.nextSessionFormSubmitLoading = true;

    if (this.nextSessionForm.invalid || !this.formValid) {
      this.nextSessionFormSubmitLoading = false;
    } else {
      this.coachingLogService.createCoachinglog(this.coachlogItemsPayload);
      this.subs.push(
        this.coachingLogService.coachlogCreated.subscribe(() => {
          this.nextSessionFormSubmitted = true;
        })
      );
    }
  }

  rateSession(ratingValue: number) {
    this.selectedSessionRating = ratingValue;
    const coachlogAttendeeId = this.session.attendees.find(
      (attendee) => attendee.userId === this.user.id
    )?.id;
    if (coachlogAttendeeId) {
      this.coachingLogService
        .rateSession(coachlogAttendeeId, { rating: ratingValue })
        .subscribe(() => {});
    }
  }

  setConfidence(confidenceValue: number) {
    this.selectedSessionConfidence = confidenceValue;
    const coachlogAttendeeId = this.session.attendees.find(
      (attendee) => attendee.userId === this.user.id
    )?.id;
    if (coachlogAttendeeId) {
      this.coachingLogService
        .setConfidence(coachlogAttendeeId, { self_rating: confidenceValue })
        .subscribe(() => {});
    }
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
