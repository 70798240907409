import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { CoachStatsUsersAPIResponse } from '../../types/responses/coach-list.responses';

@Injectable({
  providedIn: 'root',
})
export class CoachListService {
  constructor(private apiService: APICoreService, private router: Router) {}

  fetchCoaches(): Observable<CoachStatsUsersAPIResponse | null> {
    return this.apiService.getRequest('users/coaching');
  }
}
