import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DateTime, Duration } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import {
  dateTimeFromNgbDateAndTime,
  ngbDateStructFromDate,
  ngbDateStructFromDateTime,
} from 'src/app/common/utilities/date-helpers';
import {
  TimezoneLinkBack,
  timezones,
} from 'src/app/common/utilities/time-helpers';

import { datepickerDisplayTime } from './datepicker.helpers';

export interface DatepickerOutput {
  time: number;
  timezone: TimezoneLinkBack;
}

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit, OnChanges {
  @Input() timepicker = false;

  @Input() defaultStartTime = '9:00';

  @Input() defaultEndTime = '9:30';

  @Input() pickTimezones = true;

  @Input() timezone: TimezoneLinkBack;

  @Input() minDate: Date | null;

  @Input() maxDate: Date | null;

  @Output() readonly dateSelectedUnixTimestamp: EventEmitter<
    DatepickerOutput[]
  > = new EventEmitter();

  @Output() readonly validTime: BehaviorSubject<boolean> = new BehaviorSubject(
    true
  );

  isTimeValid = true;

  @Input() initialDateUnixTimestamp = 0;

  @ViewChild('timepickerElement') timepickerElement: HTMLSelectElement;

  @Input() disabled = false;

  availableTimes: { value: string; display: string }[] = [];

  duration: string;

  selectedDate: NgbDateStruct;

  selectedTimezone: TimezoneLinkBack;

  selectedStartTime = '9:00';

  selectedEndTime = '9:30';

  timezoneList: { value: string; display: string }[] = [];

  ngbMinDate: NgbDateStruct;

  ngbMaxDate: NgbDateStruct;

  setDate(): void {
    if (this.timepicker) {
      const { startDatetime, endDatetime } = this.getTime();
      this.dateSelectedUnixTimestamp.emit([
        {
          time: startDatetime.toMillis(),
          timezone: this.selectedTimezone,
        },
        {
          time: endDatetime.toMillis(),
          timezone: this.selectedTimezone,
        },
      ]);
    } else {
      const date = dateTimeFromNgbDateAndTime(
        this.selectedDate,
        '1:00',
        this.selectedTimezone
      );
      this.dateSelectedUnixTimestamp.emit([
        {
          time: date.toMillis(),
          timezone: this.selectedTimezone,
        },
      ]);
    }
  }

  getTime(): { startDatetime: DateTime; endDatetime: DateTime } {
    const startDatetime = dateTimeFromNgbDateAndTime(
      this.selectedDate,
      this.selectedStartTime,
      this.selectedTimezone
    );
    const endDatetime = dateTimeFromNgbDateAndTime(
      this.selectedDate,
      this.selectedEndTime,
      this.selectedTimezone
    );
    const duration = Duration.fromMillis(
      endDatetime.toMillis() - startDatetime.toMillis()
    );
    if (endDatetime.toMillis() - startDatetime.toMillis() > 3599999) {
      this.duration = duration.toFormat('h:mm');
    } else {
      this.duration = `${duration.toFormat('m')} min.`;
    }
    this.isTimeValid = endDatetime.toMillis() - startDatetime.toMillis() > -1;
    this.validTime.next(this.isTimeValid);
    return { startDatetime, endDatetime };
  }

  generateAvailableTimes(): void {
    // 5am to 8:45pm
    Array.from(Array.from({ length: 15 }).keys()).forEach((hour) => {
      hour = (hour as number) + 5;
      [...Array(60).keys()].forEach((minute) => {
        const minuteString = minute < 10 ? `0${minute}` : `${minute}`;
        const value = `${hour}:${minuteString}`;
        this.availableTimes.push({
          value,
          display: datepickerDisplayTime(hour, minuteString),
        });
      });
    });
  }

  generateTimezones(): void {
    this.timezoneList = Object.keys(TimezoneLinkBack).map((key) => ({
      value: TimezoneLinkBack[key as keyof typeof TimezoneLinkBack],
      display: TimezoneLinkBack[key as keyof typeof TimezoneLinkBack],
    }));
  }

  setMinMaxDates() {
    if (this.minDate) {
      this.ngbMinDate = ngbDateStructFromDate(this.minDate);
      this.config.outsideDays = 'hidden';
    }
    if (this.maxDate) {
      this.ngbMaxDate = ngbDateStructFromDate(this.maxDate);
      this.config.outsideDays = 'hidden';
    }
  }

  constructor(private config: NgbDatepickerConfig) {
    this.generateAvailableTimes();
    this.generateTimezones();
  }

  ngOnInit(): void {
    if (!this.timezone) {
      this.selectedTimezone =
        (timezones.find(
          (tz) =>
            tz.regional === Intl.DateTimeFormat().resolvedOptions().timeZone
        )?.linkBack as TimezoneLinkBack) || TimezoneLinkBack.Central;
    } else {
      this.selectedTimezone = this.timezone;
    }
    if (this.initialDateUnixTimestamp !== 0) {
      const date = DateTime.fromSeconds(this.initialDateUnixTimestamp, {
        zone: this.selectedTimezone,
      });
      this.selectedDate = ngbDateStructFromDateTime(
        date,
        this.selectedTimezone
      );
    } else {
      this.selectedDate = ngbDateStructFromDate(new Date());
    }
    if (this.timepicker) {
      if (this.defaultStartTime) {
        this.selectedStartTime = this.defaultStartTime;
      }
      if (this.defaultEndTime) {
        this.selectedEndTime = this.defaultEndTime;
      }
    }
    this.setDate();

    this.setMinMaxDates();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['minDate'] || changes['maxDate']) {
      if (
        changes['minDate'].currentValue !== changes['minDate'].previousValue ||
        changes['maxDate'].currentValue !== changes['maxDate'].previousValue
      ) {
        this.setMinMaxDates();
      }
    }
  }
}
