<div class="d-flex flex-row mb-2 align-items-top">
  <button
    aria-label="Checkbox"
    class="btn-checkbox me-2"
    [class.checked]="todo.completed"
    (click)="handleCheck()">
    <i *ngIf="todo.completed" class="bi bi-check"></i>
  </button>
  <div
    class="todo-title w-100"
    [class.overdue]="todo.overdue && !todo.completed">
    {{ todo.content | stripHTML }}
    <button
      class="p-0 border-0 ms-2 pencil"
      role="button"
      title="Edit To Do"
      (click)="handleEdit()">
      <i class="bi bi-pencil-fill tool"></i>
    </button>
  </div>
  <div
    class="todo-date text-end"
    [class.overdue]="todo.overdue && !todo.completed">
    <ng-container *ngIf="todo?.dueDate">
      <ng-container *ngIf="!isPlanItem; else planDate">
        {{ todo.dueDate | formatDateMMDDYY }}
      </ng-container>
      <ng-template #planDate>
        {{ todo.dueDate | formatDateMMDDYY : "GMT" }}
      </ng-template>
    </ng-container>
  </div>
</div>
