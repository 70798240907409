import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, zip } from 'rxjs';
import {
  DistrictDTO,
  DistrictEmailNotificationDTO,
  DistrictGoalItemDTO,
  DistrictPayloadDTO,
  EmailNotificationPayload,
  GoalType,
} from 'src/app/common/dtos/district.dto';
import { SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { User } from 'src/app/common/state/user/user.model';
import {
  UpdateUserDistrict,
  UserState,
} from 'src/app/common/state/user/user.state';
import { booleanNumberFromBoolean } from 'src/app/common/utilities/enums/boolean-number.enum';
import { districtEmailNotificationDTOfromAPIResponse } from '../../shared/helpers/translators/districts.translators';
import { AlertService } from '../../shared/services/alert/alert.service';
import { DistrictListService } from '../../shared/services/district-list/district-list-service';
import { FEATURE_FLAGS } from '../../shared/services/unleash/unleash.helpers';
import { UnleashService } from '../../shared/services/unleash/unleash.service';

@Component({
  selector: 'app-configuration-page',
  templateUrl: './configuration-page.component.html',
  styleUrls: ['./configuration-page.component.scss'],
})
export class ConfigurationPageComponent implements OnInit {
  isPageLoading: boolean;

  districts: DistrictDTO[];

  selectedDistrict: DistrictDTO | undefined;

  districtCode: string;

  user: User;

  configurationForm: FormGroup;

  selectedYear: SchoolYearDTO | null = null;

  selectedDefaultPrivateLogs = false;

  emailNotifications: DistrictEmailNotificationDTO[] = [];

  notificationsUpdated: EmailNotificationPayload[];

  emailSettingsEnabled = false;

  tableData: DistrictGoalItemDTO[];

  GoalType = GoalType;

  yearSelected = false;

  submitLoading = false;

  months = [
    { name: 'annual', key: 'value' },
    { name: 'july', key: 'july_value' },
    { name: 'august', key: 'august_value' },
    { name: 'september', key: 'september_value' },
    { name: 'october', key: 'october_value' },
    { name: 'november', key: 'november_value' },
    { name: 'december', key: 'december_value' },
    { name: 'january', key: 'january_value' },
    { name: 'february', key: 'february_value' },
    { name: 'march', key: 'march_value' },
    { name: 'april', key: 'april_value' },
    { name: 'may', key: 'may_value' },
    { name: 'june', key: 'june_value' },
  ];

  constructor(
    private route: ActivatedRoute,
    private districtListService: DistrictListService,
    private apiCoreService: APICoreService,
    private store: Store,
    private alertServce: AlertService,
    private featureFlagService: UnleashService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.emailSettingsEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.emailNotificationSettings
    );
  }

  ngOnInit(): void {
    this.notificationsUpdated = [];

    // Start Page Loader
    this.isPageLoading = true;

    // Grab District Code
    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;

    // Form Creation
    this.configurationForm = new FormGroup({
      selectedDefaultPrivateLogs: new FormControl(
        this.selectedDefaultPrivateLogs
      ),
    });

    // Fill out Form and close loader
    this.districtListService
      .fetchDistrictsSimple({ per_page: 10000 })
      .subscribe((districts) => {
        this.districts = districts as DistrictDTO[];
        this.selectedDistrict = this.districts.find(
          (district) => district.districtCode === this.districtCode
        );

        if (this.selectedDistrict?.defaultPrivateLogs) {
          this.configurationForm.patchValue({
            selectedDefaultPrivateLogs: true,
          });
        } else {
          this.configurationForm.patchValue({
            selectedDefaultPrivateLogs: false,
          });
        }

        if (this.selectedDistrict?.reportingSchoolYear) {
          this.apiCoreService
            .getRequest(
              `reporting/school-years/by-name/${this.selectedDistrict.reportingSchoolYear}`
            )
            .subscribe((schoolYear) => {
              this.selectedYear = schoolYear.item as SchoolYearDTO;
            });
        }

        if (this.selectedDistrict?.id) {
          this.getEmailNotifications();
          this.apiCoreService
            .getRequest(
              `growelab/district-goals/for-district/${this.selectedDistrict.id}`
            )
            .subscribe((results) => {
              if (results) {
                this.tableData = results.items;

                // Sort the tableData based on order in design
                this.sortTableData();

                // Loop through tableData and generate the form controls dynamically
                this.tableData.forEach((districtGoal) => {
                  const goalId = districtGoal.goal.id;

                  // Loop through each month and add controls
                  this.months.forEach((month) => {
                    const value =
                      districtGoal[month.key as keyof DistrictGoalItemDTO];
                    if (
                      typeof value === 'string' ||
                      typeof value === 'number'
                    ) {
                      // Ensure value is string or number
                      this.addControlForMonth(goalId, month.name, value);
                    }
                  });
                });

                this.isPageLoading = false;
              }
            });
        }
      });
  }

  // New method to sort tableData based on custom order
  sortTableData(): void {
    const inputOrder = ['Coaching', 'Observations'];
    const outcomeOrder = ['Growth Rate', 'Skills Attained', 'Celebrations'];

    this.tableData.sort((a, b) => {
      const aType =
        a.goal.type === GoalType.INPUT
          ? inputOrder.indexOf(a.goal.title)
          : outcomeOrder.indexOf(a.goal.title);
      const bType =
        b.goal.type === GoalType.INPUT
          ? inputOrder.indexOf(b.goal.title)
          : outcomeOrder.indexOf(b.goal.title);

      // Sort by type first (Inputs vs Outcomes), then by custom order
      if (a.goal.type === b.goal.type) {
        return aType - bType; // Sort within the same type
      }
      return a.goal.type === GoalType.INPUT ? -1 : 1; // Inputs come before Outcomes
    });
  }

  addControlForMonth(
    goalId: number,
    month: string,
    value: string | number
  ): void {
    this.configurationForm.addControl(
      `goal_${goalId}_${month}`,
      new FormControl(parseFloat(value as string), {
        validators: [
          Validators.required,
          (control: AbstractControl): { [key: string]: unknown } | null => {
            const val = control.value;
            return val < 0 ? { negative: true } : null;
          },
        ],
      })
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getControlName(goalId: number, month: string): string {
    return `goal_${goalId}_${month}`;
  }

  get f() {
    return this.configurationForm.controls;
  }

  onSubmit() {
    this.submitLoading = true;
    let yearPayload: DistrictPayloadDTO;
    const submitPayload = {
      districtGoals: this.tableData.map((districtGoal) => ({
        id: districtGoal.id,
        value:
          this.configurationForm.value[`goal_${districtGoal.goal.id}_annual`],
        july_value:
          this.configurationForm.value[`goal_${districtGoal.goal.id}_july`],
        august_value:
          this.configurationForm.value[`goal_${districtGoal.goal.id}_august`],
        september_value:
          this.configurationForm.value[
            `goal_${districtGoal.goal.id}_september`
          ],
        october_value:
          this.configurationForm.value[`goal_${districtGoal.goal.id}_october`],
        november_value:
          this.configurationForm.value[`goal_${districtGoal.goal.id}_november`],
        december_value:
          this.configurationForm.value[`goal_${districtGoal.goal.id}_december`],
        january_value:
          this.configurationForm.value[`goal_${districtGoal.goal.id}_january`],
        february_value:
          this.configurationForm.value[`goal_${districtGoal.goal.id}_february`],
        march_value:
          this.configurationForm.value[`goal_${districtGoal.goal.id}_march`],
        april_value:
          this.configurationForm.value[`goal_${districtGoal.goal.id}_april`],
        may_value:
          this.configurationForm.value[`goal_${districtGoal.goal.id}_may`],
        june_value:
          this.configurationForm.value[`goal_${districtGoal.goal.id}_june`],
      })),
    };

    if (this.selectedDistrict) {
      yearPayload = {
        title: this.selectedDistrict.title,
        domain: this.selectedDistrict.domain,
        is_internal: this.selectedDistrict.isInternal,
        is_b2b: this.selectedDistrict.isB2b,
        address: this.selectedDistrict.address,
        address2: this.selectedDistrict.address2,
        city: this.selectedDistrict.city,
        state: this.selectedDistrict.state,
        zip: this.selectedDistrict.zip,
        phone: this.selectedDistrict.phone,
        logo: this.selectedDistrict.logo,
        timezone: this.selectedDistrict.timezone,
        hubspot_company_id: this.selectedDistrict.hubspotCompanyId,
        district_code: this.selectedDistrict.districtCode,
        data_contact_email: '',
        why: '',
        reporting_school_year: this.selectedYear?.name
          ? (this.selectedYear.name as string)
          : '',
        default_private_logs: this.configurationForm.value
          .selectedDefaultPrivateLogs
          ? 1
          : 0,
        users_can_login: this.selectedDistrict.users_can_login,
      };

      const goalsCall$ = this.apiCoreService
        .putRequest('growelab/district-goals', submitPayload)
        .pipe(
          catchError((error) => {
            throw error;
          })
        );

      const districtCall$ = this.districtListService
        .updateDistrict(this.selectedDistrict.id.toString(), yearPayload)
        .pipe(
          catchError((error) => {
            throw error;
          })
        );

      // Check if notification updates
      if (this.notificationsUpdated.length > 0) {
        const submitEmailNotificationPayload = {
          email_notifications: this.notificationsUpdated,
        };
        this.notificationsUpdated = [];

        const emailNotificationCall$ = this.districtListService
          .updateDistrictEmaillNotifications(
            submitEmailNotificationPayload,
            this.selectedDistrict?.id
          )
          .pipe(
            catchError((error) => {
              throw error;
            })
          );

        zip(goalsCall$, districtCall$, emailNotificationCall$).subscribe({
          next: ([apiResponse, districtResponse, emailResponse]) => {
            if (apiResponse && districtResponse && emailResponse) {
              if (
                this.user?.district &&
                this.user.district.id === districtResponse.item.id
              ) {
                this.user.district.reportingSchoolYear =
                  districtResponse.item.reporting_school_year;

                if (districtResponse.item.default_private_logs === '1') {
                  this.user.district.defaultPrivateLogs = true;
                } else {
                  this.user.district.defaultPrivateLogs = false;
                }

                this.store.dispatch(new UpdateUserDistrict(this.user.district));
              }
              this.submitLoading = false;
              this.alertServce.showAlert('Configuration Form Saved!');
            }
          },
          error: (error) => {
            this.submitLoading = false;
            if (error.apiError && error.districtError) {
              throw new Error('Both services failed.');
            } else if (error.apiError) {
              throw new Error('Goals call failed.');
            } else if (error.districtError) {
              throw new Error('District call failed.');
            } else {
              throw new Error('Unexpected error format.');
            }
          },
        });
      } else {
        zip(goalsCall$, districtCall$).subscribe({
          next: ([apiResponse, districtResponse]) => {
            if (apiResponse && districtResponse) {
              if (
                this.user?.district &&
                this.user.district.id === districtResponse.item.id
              ) {
                this.user.district.reportingSchoolYear =
                  districtResponse.item.reporting_school_year;

                if (districtResponse.item.default_private_logs === '1') {
                  this.user.district.defaultPrivateLogs = true;
                } else {
                  this.user.district.defaultPrivateLogs = false;
                }

                this.store.dispatch(new UpdateUserDistrict(this.user.district));
              }
              this.submitLoading = false;
              this.alertServce.showAlert('Configuration Form Saved!');
            }
          },
          error: (error) => {
            this.submitLoading = false;
            if (error.apiError && error.districtError) {
              throw new Error('Both services failed.');
            } else if (error.apiError) {
              throw new Error('Goals call failed.');
            } else if (error.districtError) {
              throw new Error('District call failed.');
            } else {
              throw new Error('Unexpected error format.');
            }
          },
        });
      }
    }
  }

  onYearSelected(year: SchoolYearDTO | null) {
    if (year) {
      this.yearSelected = true;
      this.selectedYear = year;
    }
  }

  getEmailNotifications() {
    if (this.selectedDistrict?.id) {
      this.districtListService
        .fetchEmailNotifications(this.selectedDistrict.id)
        .subscribe((resp) => {
          if (resp.items) {
            this.emailNotifications = resp.items.map((item) =>
              districtEmailNotificationDTOfromAPIResponse(item)
            );
          }
        });
    }
  }

  onCheckboxChange(event: Event): void {
    const target = event.target as HTMLInputElement | null;
    if (target) {
      const notificationsIndex = this.notificationsUpdated.findIndex(
        (value) => value.email_notification_id === parseInt(target.id)
      );

      if (notificationsIndex >= 0) {
        this.notificationsUpdated[notificationsIndex].is_enabled =
          booleanNumberFromBoolean(target.checked);
      } else {
        this.notificationsUpdated.push({
          email_notification_id: parseInt(target.id, 10),
          is_enabled: booleanNumberFromBoolean(target.checked),
        });
      }
    }
  }
}
