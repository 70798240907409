<div class="container">
  <app-general-header title="Observation and Feedback Log" />
  <div class="card border-0 p-3 min-vh-100">
    <ng-container
      *ngIf="sessionLoaded && !formDataLoading; else loadingTemplate">
      <ng-container
        *ngIf="sessionData && !coachLogStatus.errors; else sessionErrorMessage">
        <app-coaching-log-notes
          (hideNotesEvent)="toggleNotes()"
          [user]="user"
          [sessionId]="sessionId"
          [notesActive]="notesActive" />
        <ng-container *ngIf="hasPresentAttendee; else noAttendeeTemplate">
          <div
            class="d-flex justify-content-between align-items-center p-xxl-2">
            <a
              href="javascript:void(0)"
              (click)="navigatePreviousScreen()"
              class="text-decoration-none text-body-secondary">
              < Back to Session Info
            </a>
            <div
              *ngIf="!isCoach"
              class="read-only px-5 py-2 bg-secondary fs-16 fw-600 rounded border border-tertiary">
              <i class="bi bi-exclamation-circle me-1"></i> Viewing submitted
              form
            </div>
          </div>
          <h2 class="fs-1 fw-bold mt-4 mb-3 text-center">
            {{ sessionData.title }}
          </h2>
          <p class="text-center mx-auto" *ngIf="isCoach">
            <span *ngIf="!formLocked"
              >Use the dropdown to preview the forms, then select and fill it
              out below.<br /></span
            >Click "Submit Form" at the bottom when complete.
          </p>

          <ng-container *ngIf="isCoach">
            <hr class="my-4" />
            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center m-2">
                  <label class="fw-600 me-2" [id]="'form' + sessionData.id">
                    Form:
                  </label>
                  <ng-select
                    #formSelect
                    [labelForId]="'form' + sessionData.id"
                    [items]="formsList"
                    bindLabel="title"
                    [ngStyle]="{ minWidth: '400px' }"
                    [ngModel]="formSelectValue"
                    *ngIf="!formLocked && isCoach"
                    placeholder="Please select a form"
                    (change)="updateFormSelect($event)" />
                  <button
                    class="btn btn-primary ms-2"
                    [disabled]="disableLockFormButton"
                    *ngIf="!formLocked && isCoach"
                    name="lockFormButton"
                    (click)="lockForm()">
                    Select
                  </button>
                  <div *ngIf="formLocked">
                    {{ selectedForm?.title }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div *ngIf="sessionData && sessionData.attendees.length > 1">
                  <label class="mb-2" [id]="'attendee' + sessionData.id"
                    >Participant:</label
                  >
                  <ng-select
                    [labelForId]="'attendee' + sessionData.id"
                    [items]="sessionData.attendees"
                    [searchable]="false"
                    [clearable]="false"
                    bindLabel="fullName"
                    placeholder="Choose attendee"
                    (ngModelChange)="attendeeSelected($event)"
                    [(ngModel)]="selectedAttendee">
                  </ng-select>
                </div>
              </div>
            </div>
            <hr class="my-4" />
            <ng-container *ngIf="selectedForm">
              <div class="mx-5 mt-2">
                <div
                  class="text-center text-primary bg-light p-2 mb-3"
                  *ngIf="!formLocked && isCoach">
                  <div class="fs-4 fw-bold">FORM PREVIEW</div>
                  <div>Select form to begin</div>
                </div>
                <app-form-view
                  #formView
                  [showTitle]="false"
                  [submissionStatus]="submissionStatus"
                  [submissionData]="currentSubmission"
                  (data)="updateSubmission($event)"
                  (indicatorData)="updateIndicatorDeltas($event)"
                  [inputDisabled]="!isCoach || !formLocked"
                  [form]="selectedForm" />
              </div>
              <hr class="my-4" />
              <div class="w-100 d-flex justify-content-center my-3">
                <button
                  (click)="navigateNextScreen()"
                  [disabled]="!formLocked"
                  *ngIf="isCoach"
                  class="btn btn-primary btn-next"
                  style="pointer-events: auto">
                  Submit Form
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="currentSubmission && !selectedForm">
              <div
                class="d-flex flex-column justify-content-center align-items-center"
                style="min-height: 500px">
                <h2>This form has been archived.</h2>
                <p>
                  The data for this form is still viewable in the Observation
                  Report.
                </p>
              </div>
            </ng-container>
          </ng-container>
          <div
            *ngIf="(isCoachee || isShadower || isE2L || isAdmin) && !isCoach">
            <app-form-view
              *ngIf="currentSubmission && selectedForm"
              [showTitle]="false"
              [submissionStatus]="submissionStatus"
              [submissionData]="currentSubmission"
              (data)="updateSubmission($event)"
              [inputDisabled]="true"
              [readOnly]="true"
              [form]="selectedForm" />
          </div>
          <div
            *ngIf="
              !isCoach && !isShadower && (!currentSubmission || !selectedForm)
            ">
            <div
              *ngIf="!currentSubmission"
              class="d-flex flex-column justify-content-center align-items-center"
              style="min-height: 500px">
              <h2>No data submitted yet.</h2>
            </div>
            <div
              *ngIf="!selectedForm"
              class="d-flex flex-column justify-content-center align-items-center"
              style="min-height: 500px">
              <h2>This form has been archived.</h2>
              <p>
                The data for this form is still viewable in the Observation
                Report.
              </p>
            </div>
          </div>
        </ng-container>
        <div
          role="button"
          (click)="toggleNotes()"
          class="d-none d-sm-block border border-2 border-end-0 border-secondary rounded-start notes-fade-out notes-tab {{
            notesTabActive ? '' : 'hide'
          }} ">
          <div class="d-flex align-items-center">
            <i class="bi bi-journal journal-icon"></i>
            <p class="notes-title">Notes</p>
          </div>
        </div>
      </ng-container>
      <ng-template #sessionErrorMessage>
        <ng-container *ngIf="coachLogStatus && coachLogStatus.errors">
          <app-error-message [message]="coachLogStatus.errors[0]" />
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template #noAttendeeTemplate>
      <p>
        <ngb-alert [dismissible]="false">
          <strong>No Present Participants</strong> At least 1 participant must
          be marked present.
          <a
            routerLink="/coaching/log/{{ sessionId }}/info"
            class="text-decoration-none text-body-secondary"
            ><br />
            &lt; back
          </a>
        </ngb-alert>
      </p>
    </ng-template>
    <ng-template #loadingTemplate>
      <div class="w-100" style="height: 400px">
        <app-loader></app-loader>
      </div>
    </ng-template>
  </div>
</div>

<app-modal
  #unloadedSavedDataModal
  [modalConfig]="{
    titleText: 'Form warning',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    xCloseButton: false,
    closeOnBgClick: false,
    customFooter: true,
  }">
  <p>
    You have previously unsaved changes. Would you like to restore your form
    progress now?
  </p>

  <div
    footerContent
    class="d-flex justify-content-between flex-row-reverse w-100">
    <button
      active
      class="btn btn-primary"
      (click)="loadFormDataForUse(); unloadedSavedDataModal.close()">
      Restore progress
    </button>
    <button class="btn btn-link text-danger" (click)="deleteSavedFormData()">
      Discard changes
    </button>
  </div>
</app-modal>
<app-modal
  #saveWarningModal
  [modalConfig]="{
    customFooter: true,
    closeOnBgClick: false,
    xCloseButton: false,
    titleText: 'Form warning',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
  }">
  <p>
    You are about to leave this form but you have unsaved changes. Please either
    stay on the form to finish or discard changes and leave the page.
  </p>
  <div
    footerContent
    class="d-flex justify-content-between flex-row-reverse w-100">
    <button
      class="btn btn-primary"
      (click)="
        this.formsSaveService.clearNavigationQueue(this.sessionId);
        saveWarningModal.close()
      ">
      Stay here
    </button>
    <button
      class="btn btn-link text-danger"
      (click)="deleteSavedFormData(true)">
      Discard changes
    </button>
  </div>
</app-modal>
