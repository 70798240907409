<ng-container *ngIf="value">
  <div class="gauge-wrapper">
    <div
      echarts
      role="figure"
      class="dashboard-chart"
      [ngStyle]="{ height: '160px' }"
      [options]="chartOptions"
      [loading]="isLoading"
      [loadingOpts]="spinnerOptions"></div>
    <div class="gauge-labels d-flex justify-content-between">
      <div class="glabel">{{ min_label }}</div>
      <div class="glabel">{{ max_label }}</div>
    </div>
    <div class="chart-label fw-800">{{ title }}</div>
  </div>
</ng-container>
