<div *ngIf="phase" class="phase-progress">
  <p class="fw-800 fs-17 mb-1 me-3">Completed:</p>
  <div class="progress-wrapper position-relative">
    <div class="d-flex mt-1">
      <div *ngIf="phase.progress === 0" class="zero-percent-label">0%</div>
      <div
        *ngFor="let marker of markers"
        class="d-flex justify-content-center position-relative pb-1"
        [ngStyle]="{ width: marker.position + '%' }">
        <div class="marker-year fs-13 fw-800">
          {{ marker.year }}
        </div>
        <div class="marker-content">
          <span class="marker light"></span><span class="marker dark"></span>
        </div>
      </div>
    </div>
    <ngb-progressbar
      [value]="formatProgress(phase.progress)"
      [animated]="true"
      [height]="'23px'">
      <span class="fw-bold fs-15 text-start ms-2"
        >{{ formatProgress(phase.progress) }}%</span
      >
    </ngb-progressbar>
  </div>
</div>
