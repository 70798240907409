<div class="container container-main">
  <app-general-header
    [title]="
      ((selectedSchool && selectedSchool.title) ||
        (selectedDistrict && selectedDistrict.title) ||
        'District') + ' Reporting'
    " />
  <div class="card border-0 p-3">
    <app-reporting-subnav></app-reporting-subnav>
    <div class="card-body">
      <h2 class="fw-bolder text-center mb-4">Session Details Report</h2>
      <div class="d-flex justify-content-end">
        <button
          ngbTooltip="Download CSV"
          aria-label="Download CSV"
          class="btn"
          container="body"
          placement="bottom"
          [autoClose]="false"
          [ngbPopover]="csvDownloadingPopover"
          [disabled]="disableDownload"
          (click)="downloadCSV()"
          #p="ngbPopover">
          <i [ngClass]="['bi', 'fs-21', 'bi-download']"></i>
        </button>
        <ng-template #csvDownloadingPopover>
          <div class="p-2">
            An email will be sent to you with a link to download the CSV.
          </div>
          <div class="p-2 d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              (click)="disableDownload = false; p.close()">
              OK
            </button>
          </div>
        </ng-template>
      </div>
      <app-report-filter-bar
        [canFilterByDistrict]="canFilterByDistrict"
        [reportName]="'session_details'"></app-report-filter-bar>
      <div class="card border-0 table-container">
        <table class="table">
          <thead>
            <tr class="align-middle">
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="title"
                class="sortable-header"
                style="min-width: 250px">
                Session Title
                <i class="bi bi-caret-down {{ sortIconTemp.title }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="session_type"
                class="sortable-header"
                style="min-width: 220px">
                Session Type
                <i class="bi bi-caret-down {{ sortIconTemp.session_type }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="observation_form"
                class="sortable-header"
                style="min-width: 200px">
                Form
                <i
                  class="bi bi-caret-down {{
                    sortIconTemp.observation_form
                  }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="startdate"
                class="sortable-header"
                style="min-width: 220px">
                Date & Time
                <i class="bi bi-caret-down {{ sortIconTemp.startdate }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="duration"
                class="sortable-header"
                style="min-width: 150px">
                Duration
                <i class="bi bi-caret-down {{ sortIconTemp.duration }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="coachee"
                class="sortable-header"
                style="min-width: 250px">
                Participant
                <i class="bi bi-caret-down {{ sortIconTemp.coachee }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="is_present"
                class="sortable-header"
                style="min-width: 150px">
                Present
                <i class="bi bi-caret-down {{ sortIconTemp.is_present }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="absent_reason"
                class="sortable-header text-center"
                style="min-width: 250px">
                Reason For Absence
                <i
                  class="bi bi-caret-down {{ sortIconTemp.absent_reason }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="coach"
                class="sortable-header"
                style="min-width: 250px">
                Session Owner
                <i class="bi bi-caret-down {{ sortIconTemp.coach }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="shadower"
                class="sortable-header"
                style="min-width: 250px">
                Shadower
                <i class="bi bi-caret-down {{ sortIconTemp.shadower }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="coaching_source"
                class="sortable-header"
                style="min-width: 175px">
                Coaching Source
                <i
                  class="bi bi-caret-down {{
                    sortIconTemp.coaching_source
                  }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="school"
                class="sortable-header"
                style="min-width: 250px">
                School
                <i class="bi bi-caret-down {{ sortIconTemp.school }}"></i>
              </th>
              <th scope="col" class="sortable-header" style="min-width: 300px">
                Notes
              </th>
              <th scope="col" class="sortable-header" style="min-width: 300px">
                Competencies
              </th>
              <th scope="col" class="sortable-header" style="min-width: 150px">
                GIs Earned Count
              </th>
              <th scope="col" class="sortable-header" style="min-width: 300px">
                Badges Earned
              </th>
              <th scope="col" class="sortable-header" style="min-width: 150px">
                Badges Earned Count
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="!isLoading; else loadingTemplate">
              <ng-container *ngIf="tableData; else noTableData">
                <tr *ngFor="let log of tableData" class="align-middle">
                  <td>
                    <a
                      routerLink="{{ getSessionLink(log) }}"
                      target="_blank"
                      class="overlap-text fw-bold"
                      title="{{ log.title }}"
                      >{{ log.title }}</a
                    >
                  </td>
                  <td class="px-4">
                    {{ log.logType }}
                  </td>
                  <td class="text-center">
                    <ng-container *ngIf="log.observationForm">{{
                      log.observationForm
                    }}</ng-container>
                  </td>
                  <td class="text-center">
                    {{ log.startDatetime | formatDate : "MM/dd/yy" }},
                    {{
                      log.startDatetime | formatTime : "h:mm a" : log.timezone
                    }}
                  </td>
                  <td class="text-center">
                    {{ toHoursAndMinutes(log.durationMins) }}
                  </td>
                  <td>
                    <div
                      *ngIf="log.attendee"
                      class="d-flex flex-nowrap align-items-center">
                      <app-avatar
                        [userId]="log.attendeeUserId"
                        [avatarData]="
                          getAvatarData(
                            log.attendee,
                            log.attendeeColor,
                            log.attendeePhoto
                          )
                        "
                        [avatarSize]="'medium'"></app-avatar>
                      <div class="fw-light ms-2">
                        {{ log.attendee.split(" ")[0] }}
                        {{ log.attendee.split(" ")[1] }}
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <ng-container *ngIf="log.present === 1; else unchecked"
                      ><i class="bi bi-check2-circle text-info fs-4"></i
                    ></ng-container>
                    <ng-template #unchecked
                      ><i class="bi bi-circle-fill"></i
                    ></ng-template>
                  </td>
                  <td class="text-center">
                    <ng-container *ngIf="log.absentReason"
                      >{{ log.absentReason }}
                    </ng-container>
                  </td>
                  <td>
                    <div
                      *ngIf="log.coach"
                      class="d-flex flex-nowrap align-items-center">
                      <app-avatar
                        [userId]="log.coachUserId"
                        [avatarData]="
                          getAvatarData(
                            log.coach,
                            log.coachColor,
                            log.coachPhoto
                          )
                        "
                        [avatarSize]="'medium'"></app-avatar>
                      <div class="fw-light ms-2">
                        {{ log.coach.split(" ")[0] }}
                        {{ log.coach.split(" ")[1] }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-nowrap align-items-center">
                      <ng-container *ngFor="let shadower of log.shadowers">
                        <app-avatar
                          [userId]="shadower.shadowerUserId"
                          [avatarData]="
                            getAvatarData(
                              shadower.shadower,
                              shadower.shadowerColor,
                              shadower.shadowerPhoto
                            )
                          "
                          [avatarSize]="'medium'"></app-avatar>
                        <div class="fw-light ms-2">
                          {{ shadower.shadower.split(" ")[0] }}
                          {{ shadower.shadower.split(" ")[1] }}
                        </div>
                      </ng-container>
                    </div>
                  </td>
                  <td class="text-center">
                    {{
                      log.coachingSource.includes("engage2learn")
                        ? "engage2Learn"
                        : log.coachingSource.charAt(0).toUpperCase() +
                          log.coachingSource.slice(1)
                    }}
                  </td>
                  <td class="text-center">{{ log.school }}</td>
                  <td>
                    <app-safehtml html="{{ log.notes }}"></app-safehtml>
                  </td>
                  <td>
                    <span
                      *ngFor="let competency of log.competencies"
                      class="badge badge-item me-1"
                      title="{{ competency }}">
                      {{ competency }}
                    </span>
                  </td>
                  <td class="text-center">{{ log.giCount }}</td>
                  <td>
                    <span
                      *ngFor="let badge of log.badges"
                      class="badge badge-item me-1"
                      title="{{ badge }}">
                      {{ badge }}
                    </span>
                  </td>
                  <td class="text-center">{{ log.badgeCount }}</td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
          <ng-template #loadingTemplate>
            <div class="loader-container">
              <app-loader></app-loader>
            </div>
          </ng-template>
        </table>
        <ng-template #noTableData>
          <tr>
            <td></td>
            <td></td>
            <td>
              <p class="text-center my-5">No Sessions Found.</p>
            </td>
          </tr>
        </ng-template>
      </div>
      <section>
        <div class="d-flex justify-content-center mt-4">
          <ngb-pagination
            *ngIf="listMeta && listMeta.totalCount > 10"
            [(page)]="listMeta.currentPage"
            [pageSize]="listMeta.perPage"
            [collectionSize]="listMeta.totalCount"
            [maxSize]="5"
            (pageChange)="paginationChange()"></ngb-pagination>
        </div>
      </section>
    </div>
    <div class="mt-5 text-muted small text-end">
      Data last refreshed at {{ lastUpdatedDate }}
    </div>
  </div>
</div>
