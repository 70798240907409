<app-modal
  #modal
  [modalConfig]="{
  titleText: 'Duplicate Session',
  headerClass: ['modal-header', 'bg-primary', 'text-white'],
  windowClass: 'custom-class',
  customFooter: true,
}"
  (isClosing)="resetModal()"
  (isDismissing)="resetModal()">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="modal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <label class="form-label"> Repeat every </label>
  <div class="d-flex align-items-center mt-2">
    <div class="number-selector me-2">
      <input
        [(ngModel)]="weekInterval"
        class="form-control"
        type="number"
        min="1" />
    </div>
    week(s)
  </div>
  <label class="mt-4 form-label">Repeat on</label>
  <div class="d-flex mt-2">
    <div
      *ngFor="let day of days; let i = index"
      class="day"
      (click)="onDayClick(day)"
      [ngClass]="{ selected: isDaySelected(day) }">
      {{ day[0] }}
    </div>
  </div>
  <label class="mt-4 form-label">Ends</label>
  <div class="d-flex flex-column mt-2">
    <div class="d-flex align-items-center">
      <div class="w-25 form-check">
        <input
          class="form-check-input"
          type="radio"
          name="ends-option"
          id="ends-on"
          (click)="toggleEndsOption('on')"
          [(ngModel)]="endsOption"
          [value]="'on'" />
        <label for="ends-on">On</label>
      </div>
      <div class="w-50">
        <app-datepicker
          #datepicker
          (dateSelectedUnixTimestamp)="setEndDate($event)"
          [minDate]="minDate"
          [disabled]="endsOption !== 'on'" />
      </div>
    </div>
    <div class="d-flex align-items-center mt-3">
      <div class="w-25 form-check">
        <input
          class="form-check-input"
          type="radio"
          name="ends-option"
          id="ends-after"
          [(ngModel)]="endsOption"
          [value]="'after'"
          (click)="toggleEndsOption('after')" />
        <label for="ends-after">After</label>
      </div>
      <div
        class="w-50 d-flex align-items-center"
        [ngClass]="{ 'color-gray': endsOption !== 'after' }">
        <input
          class="form-control number-selector me-2"
          type="number"
          [(ngModel)]="occurrences"
          (ngModelChange)="onOccurrencesChange($event)"
          [disabled]="endsOption !== 'after'" />
        occurrences
      </div>
    </div>
  </div>
  <div footerContent>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="modal.close()">Cancel</button>
      <button
        (click)="createSessions()"
        [disabled]="isLoading || !formIsValid()"
        type="button"
        class="btn btn-md btn-primary">
        <ng-container *ngIf="isLoading">
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
        Save
      </button>
    </div>
  </div>
</app-modal>
