<ng-container *ngIf="session">
  <div class="container mt-5">
    <div class="row">
      <div
        [ngClass]="
          session.currentUsersRole != 'coach' &&
          session.currentUsersRole != 'editor'
            ? 'col-lg-4'
            : 'col'
        ">
        <h3>Session Summary</h3>
        <ngb-alert
          *ngIf="
            session.currentUsersRole == 'coach' ||
            session.currentUsersRole == 'editor'
          "
          type="secondary"
          class="text-start"
          [dismissible]="false">
          Encourage the participants to reflect on their session and provide
          feedback. Remind them that their feedback is anonymous.
        </ngb-alert>
        <ng-container *ngFor="let attendee of session.attendees">
          <ng-container *ngIf="attendee.present">
            <div class="mt-3 mb-5">
              <h4
                *ngIf="session.attendees.length > 1"
                class="fs-21 fw-800 text-primary bg-light py-2 mt-3">
                {{ attendee.user.profile.first_name }}
                {{ attendee.user.profile.last_name }}
              </h4>
              <h5>Badges Earned</h5>
              <ng-container
                *ngIf="
                  getAttendeeBadgeUser(attendee.user) as badgeUser;
                  else noBadges
                ">
                <div class="badge-wrapper mb-3 d-flex flex-wrap">
                  <ng-container *ngFor="let badge of badgeUser.badges">
                    <app-badge-graphic [badge]="badge" size="sm" />
                  </ng-container>
                </div>
              </ng-container>
              <ng-template #noBadges>
                <p class="fw-light text-body-secondary">
                  No badges were earned for this session.
                </p>
              </ng-template>
              <h5>Competencies</h5>
              <ng-container
                *ngFor="let rubric of getAttendeeRubrics(attendee.user.id)">
                <ng-container
                  *ngFor="let competencyGroup of rubric.competencyGroups">
                  <ng-container
                    *ngFor="let competency of competencyGroup.competencies">
                    <ng-container
                      *ngFor="let strand of competency.indicatorSet.strands">
                      <div ngbAccordion>
                        <div
                          ngbAccordionItem
                          [collapsed]="false"
                          class="accordion">
                          <h2 ngbAccordionHeader>
                            <button ngbAccordionButton>
                              {{ competency.title }}
                            </button>
                          </h2>
                          <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                              <ng-template>
                                <div class="my-2">
                                  <p class="fw-bold mb-1">
                                    {{ strand.title }}:
                                    <span
                                      class="text-level-{{ strand.goalLevel }}"
                                      >Level {{ strand.goalLevel }}</span
                                    >
                                  </p>
                                  <div class="w-75">
                                    <app-quartile-progress-bar
                                      description="{{
                                        attendee.user.profile.first_name
                                      }} {{
                                        attendee.user.profile.last_name
                                      }}'s progress on {{ strand.title }}"
                                      [progress]="getStrandProgress(strand)"
                                      [goalLevel]="
                                        strand.goalLevel
                                      "></app-quartile-progress-bar>
                                  </div>
                                  <ng-container
                                    *ngFor="let level of getLevels(strand)">
                                    <ng-container
                                      *ngFor="let option of level.options">
                                      <ng-container *ngIf="option.will">
                                        <ul>
                                          <li class="d-flex">
                                            <span class="fw-lighter w-75 pe-2">
                                              {{ option.content }}
                                            </span>
                                            <span class="fw-bold float-end">{{
                                              option.will.dueDate
                                                | formatDateMMDDYY
                                            }}</span>
                                          </li>
                                        </ul>
                                      </ng-container>
                                    </ng-container>
                                  </ng-container>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <ng-container
        *ngIf="
          session.currentUsersRole != 'coach' &&
          session.currentUsersRole != 'editor'
        ">
        <div class="col-lg-4 mb-5">
          <h3>Reflection</h3>
          <p>
            Your ratings will be anonymous and are only visible in this log to
            you.
          </p>
          <h5 class="mt-3">Confidence</h5>
          <p>Rate your level of confidence after today's session:</p>
          <div class="d-flex mt-2 mb-4">
            <ng-container
              class="div"
              *ngFor="let conf of confidence; index as i">
              <div class="conf-item d-flex flex-column align-items-center">
                <div
                  (click)="setConfidence(conf)"
                  [ngClass]="{ filled: selectedSessionConfidence === conf }"
                  class="rating-circle rating-{{ conf }}">
                  {{ conf }}
                </div>
                <div class="label mt-2">
                  <ng-container *ngIf="i === 0">Doubtful</ng-container>
                  <ng-container *ngIf="i === 1">Cautious</ng-container>
                  <ng-container *ngIf="i === 2">Assured</ng-container>
                  <ng-container *ngIf="i === 3">Confident</ng-container>
                </div>
              </div>
            </ng-container>
          </div>
          <h5 class="mt-3">Feedback</h5>
          <p>Rate the effectiveness of today's session:</p>
          <div class="d-flex mt-2 mb-4">
            <ng-container *ngFor="let rating of ratings; index as i">
              <div class="conf-item d-flex flex-column align-items-center">
                <div
                  (click)="rateSession(rating)"
                  [ngClass]="{ filled: selectedSessionRating === rating }"
                  class="rating-circle rating-{{ rating }}">
                  {{ rating }}
                </div>
                <div class="label mt-2">
                  <ng-container *ngIf="i === 0">Ineffective</ng-container>
                  <ng-container *ngIf="i === 1">Neutral</ng-container>
                  <ng-container *ngIf="i === 2">Valuable</ng-container>
                  <ng-container *ngIf="i === 3">Game Changer</ng-container>
                </div>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="feedback.length > 0; else addFeedback">
            <ng-container *ngFor="let feedbackItem of feedback">
              <div class="d-block my-3">
                <div class="d-flex flex-nowrap align-items-center mt-5 mb-2">
                  <app-avatar
                    [profile]="feedbackItem.user.profile"
                    [avatarSize]="'small'">
                  </app-avatar>
                  <p class="ms-2 mb-0">
                    {{ feedbackItem.user.profile.first_name }}
                    {{ feedbackItem.user.profile.last_name }}
                  </p>
                  <div class="ms-auto">
                    <button
                      class="bg-transparent border-0 p-0 me-3"
                      title="Edit Feedback"
                      aria-label="Edit feedback"
                      (click)="toggleEditMode(feedbackItem.content)">
                      <i class="bi bi-pencil-fill"></i>
                    </button>
                    <button
                      class="bg-transparent border-0 p-0"
                      title="Delete Feedback"
                      aria-label="Delete feedback"
                      [autoClose]="'outside'"
                      [ngbPopover]="deleteFeedbackTemplate"
                      [popoverTitle]="'Delete Feedback'"
                      #popover="ngbPopover">
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
                <ng-template #deleteFeedbackTemplate>
                  Are you sure you want to delete this feedback?
                  <div class="text-end mt-2">
                    <button
                      type="button"
                      class="btn btn-secondary btn-sm px-3 m-1"
                      (click)="popover.close()">
                      No
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm px-3 m-1"
                      (click)="
                        deleteFeedback(feedbackItem.id); popover.close()
                      ">
                      Yes
                    </button>
                  </div>
                </ng-template>
                <ng-container *ngIf="isEditingFeedback; else feedbackContent">
                  <form
                    (ngSubmit)="editFeedback(feedbackItem.id)"
                    [formGroup]="editFeedbackForm">
                    <textarea
                      formControlName="feedback"
                      class="form-control mb-3"
                      [ngClass]="{
                        'is-invalid':
                          editFeedbackFormSubmitted &&
                          this.editFeedbackForm.controls['feedback'].errors
                      }"
                      id="feedback"
                      aria-label="feedback"
                      rows="8">
                    </textarea>
                    <button
                      type="submit"
                      class="btn btn-primary px-4 fw-bold"
                      [disabled]="editFeedbackFormSubmitLoading">
                      <span
                        *ngIf="editFeedbackFormSubmitLoading"
                        class="spinner-border spinner-border-sm me-1"></span>
                      Submit
                    </button>
                  </form>
                </ng-container>
                <ng-template #feedbackContent>
                  {{ feedbackItem.content }}
                </ng-template>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #addFeedback>
            <p class="mt-5">
              What feedback do you have for your coach and/or what did you find
              most valuable about your session today?
            </p>
            <form (ngSubmit)="submitFeedback()" [formGroup]="feedbackForm">
              <textarea
                formControlName="feedback"
                class="form-control mb-3"
                [ngClass]="{
                  'is-invalid':
                    feedbackFormSubmitted &&
                    this.feedbackForm.controls['feedback'].errors
                }"
                id="feedback"
                aria-label="feedback"
                placeholder="Leave feedback for your coach"
                rows="4"></textarea>
              <button
                type="submit"
                class="btn btn-primary px-4 fw-bold"
                [disabled]="feedbackFormSubmitLoading">
                <span
                  *ngIf="feedbackFormSubmitLoading"
                  class="spinner-border spinner-border-sm me-1"></span>
                Submit
              </button>
            </form>
          </ng-template>
        </div>
      </ng-container>
      <div
        [ngClass]="
          session.currentUsersRole != 'coach' &&
          session.currentUsersRole != 'editor'
            ? 'col-lg-3'
            : 'col-lg-5'
        ">
        <h3>Next Session</h3>
        <ng-container *ngIf="!scheduledSession; else scheduledSessionInfo">
          <ng-container
            *ngIf="
              session.currentUsersRole === 'coach';
              else noScheduledSession
            ">
            <form
              (ngSubmit)="scheduleNextSession()"
              [formGroup]="nextSessionForm">
              <label for="title" class="form-label fw-bold">Title</label>
              <div class="input-group mb-2">
                <input
                  formControlName="title"
                  type="text"
                  class="form-control"
                  id="title"
                  aria-label="Title"
                  [ngClass]="{
                    'is-invalid':
                      nextSessionFormSubmitted &&
                      this.nextSessionForm.controls['title'].errors
                  }" />
              </div>
              <div class="mb-3">
                <label for="type" class="form-label fw-bold">Type</label>
                <app-log-type-select
                  [defaultLogTypes]="[this.session.type]"
                  [clearAfterSelection]="false"
                  [fieldInvalid]="
                    nextSessionFormSubmitted && !coachlogItemsPayload.logType
                  "
                  [omitMSC]="true"
                  (selectedLogType)="
                    updateSessionLogType($event)
                  "></app-log-type-select>
              </div>
              <div class="mb-3">
                <label class="form-label fw-bolder">Date & Time:</label>
                <app-datepicker
                  (dateSelectedUnixTimestamp)="updateDateTime($event)"
                  [initialDateUnixTimestamp]="tomorrowsDateTime"
                  [minDate]="tomorrowsDate"
                  [timepicker]="true"
                  [timezone]="defaultTimezone"></app-datepicker>
              </div>
              <button
                type="submit"
                class="btn btn-primary px-4 fw-bold"
                [disabled]="nextSessionFormSubmitLoading">
                <span
                  *ngIf="nextSessionFormSubmitLoading"
                  class="spinner-border spinner-border-sm me-1"></span>
                Submit
              </button>
            </form>
          </ng-container>
          <ng-template #noScheduledSession>
            <p class="fw-light text-body-secondary mt-3">
              No session has been scheduled yet.
            </p>
          </ng-template>
        </ng-container>
        <ng-template #scheduledSessionInfo>
          <div class="d-flex">
            <a
              title="{{ scheduledSession.title }}"
              routerLink="/coaching/log/{{ scheduledSession.logId }}/info"
              aria-current="page"
              target="_blank">
              <p class="fw-bold mb-2">
                {{ scheduledSession.title }}&nbsp;
                <i class="bi bi-box-arrow-up-right"></i>
              </p>
            </a>
          </div>
          <p class="mb-1">{{ scheduledSession.coachlogType }}</p>
          <p class="mb-1">
            {{
              scheduledSession.startDatetime
                | formatDate : "MMM. d, yyyy" : scheduledSession.timezone
            }},
          </p>
          <p class="mb-1">
            {{
              scheduledSession.startDatetime
                | formatDate : "h:mm a" : scheduledSession.timezone
            }}
            -
            {{
              scheduledSession.endDatetime
                | formatDate : "h:mm a" : scheduledSession.timezone
            }}
          </p>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
