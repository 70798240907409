<app-modal
  #modal
  [modalConfig]="{
    titleText: 'Filter Results',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <div *ngIf="canFilterByDistrict" class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">District:</label>
      <app-district-select
        #districtSelect
        [clearAfterSelection]="false"
        [simpleSelect]="true"
        [defaultDistricts]="
          this.currentFilters.district ? [this.currentFilters.district] : []
        "
        (selectedDistrict)="clearFilters($event)" />
    </div>
  </div>
  <div class="row datePickerRow noTop gy-3">
    <label class="form-label">Date Range:</label>
    <app-daterange-picker
      [fromDate]="this.currentFilters.fromDate"
      [toDate]="this.currentFilters.toDate"
      (selectedFromDate)="$event && (this.currentFilters.fromDate = $event)"
      (selectedToDate)="
        $event && (this.currentFilters.toDate = $event)
      "></app-daterange-picker>
  </div>
  <div class="row gy-3">
    <div class="col-md-12 gx-5" ngbDropdown>
      <label class="form-label">Session Type:</label>
      <div class="input-style-row">
        <app-log-type-select
          #logTypeSelect
          [clearAfterSelection]="false"
          [districtId]="
            this.currentFilters.district
              ? this.currentFilters.district.id
              : undefined
          "
          [defaultLogTypes]="
            this.currentFilters.logType ? [this.currentFilters.logType] : null
          "
          (selectedLogType)="
            this.currentFilters.logType = $event
          "></app-log-type-select>
      </div>
    </div>
  </div>
  <div class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">Competencies:</label>
      <app-competency-select
        #competencySelect
        [clearAfterSelection]="false"
        [isMulti]="true"
        [districtIds]="
          this.currentFilters.district ? [this.currentFilters.district.id] : []
        "
        [defaultCompetencies]="this.currentFilters.competencyList"
        (selectedCompetencyList)="
          this.currentFilters.competencyList = $event
        " />
    </div>
  </div>
  <div class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">Schools:</label>
      <app-school-select
        #schoolSelect
        [clearAfterSelection]="false"
        [isMulti]="true"
        [districtId]="
          this.currentFilters.district
            ? [this.currentFilters.district.id]
            : this.user?.district?.id || undefined
        "
        [assignedCoacheesSchoolsOnly]="!canSeeAllSchools"
        [defaultSchools]="this.currentFilters.schoolList"
        (selectedSchoolList)="updateSchools($event)" />
    </div>
  </div>
  <div class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">Owners:</label>
      <app-user-select
        #coachSelect
        [clearAfterSelection]="false"
        [isMulti]="true"
        [districtId]="
          this.currentFilters.district
            ? this.currentFilters.district.id
            : this.user?.district?.id || null
        "
        [defaultUsers]="this.currentFilters.coachList"
        (selectedUserList)="this.currentFilters.coachList = $event" />
    </div>
  </div>
  <div class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">Participants:</label>
      <app-user-select
        #coacheeSelect
        [clearAfterSelection]="false"
        [isMulti]="true"
        [districtId]="
          this.currentFilters.district
            ? this.currentFilters.district.id
            : this.user?.district?.id || null
        "
        [defaultUsers]="this.currentFilters.coacheeList"
        (selectedUserList)="this.currentFilters.coacheeList = $event" />
    </div>
  </div>
  <div footerContent>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="clearFilters()">
        Clear All
      </button>
      <button
        type="button"
        class="btn btn-md btn-primary"
        (click)="closeModal(true)">
        Apply
      </button>
    </div>
  </div>
</app-modal>
