import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Channel } from 'pusher-js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  EvidenceDTO,
  EvidenceType,
  LevelDTO,
  OptionDTO,
} from 'src/app/private/shared/dtos/attendee-rubric.dto';
import { FileDTO } from 'src/app/private/shared/dtos/file.dto';
import { UploadType } from 'src/app/private/shared/enums/upload-type.enum';
import { VideoReferenceObjectType } from 'src/app/private/shared/enums/video-reference-object-type.enum';
import { fileDTOFromEvidenceAttachment } from 'src/app/private/shared/helpers/translators/evidence.translators';
import { CoachingLogEvidenceService } from 'src/app/private/shared/services/coaching-log/coaching-log-evidence.service';
import { EvidenceService } from 'src/app/private/shared/services/evidence/evidence.service';
import { EvidenceFileService } from 'src/app/private/shared/services/file/evidence.file.service';
import { FEATURE_FLAGS } from 'src/app/private/shared/services/unleash/unleash.helpers';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';
import { EvidencePayload } from 'src/app/private/shared/types/payloads/evidence.payload';
import { FileListComponent } from '../../../file-management/file-list/file-list.component';
import {
  FileUploadComponent,
  UploadStyles,
} from '../../../file-management/file-upload/file-upload.component';
import { CopyEvidenceModalComponent } from '../../../modals/copy-evidence-modal/copy-evidence-modal.component';
import {
  ModalComponent,
  ModalConfig,
} from '../../../modals/modal/modal.component';
import { EvidenceSelfAssessmentModalComponent } from '../../../my-smart-coach/evidence-self-assessment-modal/evidence-self-assessment-modal.component';
import { WysiwygEditorComponent } from '../../../wysiwyg-editor/wysiwyg-editor.component';
import { evidenceModalTypes } from './evidence-modal.helpers';

@Component({
  selector: 'app-leveled-competency-strand-level-evidence',
  templateUrl: './leveled-competency-strand-level-evidence.component.html',
  styleUrls: ['./leveled-competency-strand-level-evidence.component.scss'],
})
export class LeveledCompetencyStrandLevelEvidenceComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('evidenceModal') evidenceModal: ModalComponent;

  @ViewChild('copyEvidenceModal') copyEvidenceModal: CopyEvidenceModalComponent;

  @ViewChild('evidenceSelfAssessmentModal')
  evidenceSelfAssessmentModal: EvidenceSelfAssessmentModalComponent;

  @ViewChild('uploadComponent') uploadComponent: FileUploadComponent;

  @ViewChild('fileListComponent') fileListComponent: FileListComponent;

  @ViewChild('optionSelect') optionSelect: NgSelectComponent;

  @ViewChild('contentEditor') contentEditor: WysiwygEditorComponent;

  @Input() levelData: LevelDTO;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() level: number;

  @Input() strandId: number;

  @Input() strandTitle: string;

  @Input() competencyId: number;

  @Input() competencyTitle: string;

  @Input() coachlogId: number;

  @Input() isSmart = false;

  @Input() coachUser: UserDTO | undefined;

  @Input() canCreateEvidence = true;

  @Input() viewOnly = false;

  @Input() viewingPrevSession = false;

  disableVideoRemoval = false;

  optionList: OptionDTO[] = [];

  portfolioOnly: OptionDTO;

  optionData$ = select(CoachingLogState.getOptions);

  evidenceData$ = select(CoachingLogState.getEvidence);

  modalFileList: FileDTO[] = [];

  modalConfig: ModalConfig;

  uploadTypes = UploadType;

  uploadType: UploadType | null;

  uploadStyle = UploadStyles.EVIDENCE;

  uploadedFiles: FileDTO[] = [];

  selectedOption: OptionDTO;

  selectedOptionId?: number;

  numberOfFilesUploaded = 0;

  evidenceModalTypes = evidenceModalTypes;

  newEvidence = false;

  disableEvidenceSubmit = new BehaviorSubject<boolean>(false);

  currentEvidence: EvidenceDTO;

  initialNarrativeContent = '';

  evidenceList: EvidenceDTO[] = [];

  evidenceByOption: { [key: number]: EvidenceDTO[] } = {};

  subscriptions: Subscription[] = [];

  payload: EvidencePayload;

  hasNarrative = false;

  hasTitle = false;

  userChannel: Channel;

  isDrawerCollapsed = true;

  currentLogId: number;

  copyEvidenceEnabled = false;

  constructor(
    private evidenceService: EvidenceService,
    private coachingLogEvidenceService: CoachingLogEvidenceService,
    private featureFlagService: UnleashService,
    private changeRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.currentLogId = params['logId'];
    });

    this.copyEvidenceEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.copyEvidence
    );

    this.portfolioOnly = {
      id: 0,
      content: 'Just adding evidence to my portfolio',
      fromBankOption: null,
      fromCuratedOptionId: null,
      fromCuratedOption: null,
      resources: [],
      strandId: this.strandId,
      level: this.level,
      coachlogId: this.coachlogId,
      will: null,
    };

    this.modalConfig = {
      headerClass: [
        `bg-level-${this.level}`,
        'text-white',
        'text-center',
        'modal-header',
      ],
      titleText: '',
      options: {
        size: 'lg',
      },
      customFooter: true,
    };
    this.resetCurrentEvidence();
    this.evidenceData$
      .pipe(
        map((filterFn) =>
          filterFn(this.userId, this.strandId, this.level, this.coachlogId)
        )
      )
      .subscribe((newData) => {
        this.evidenceList.forEach((evidence) => {
          const currentIdx = newData.findIndex(
            (newEvidence) => newEvidence.id === evidence.id
          );
          if (currentIdx === -1) {
            this.removedEvidence(evidence);
          }
        });
        newData.forEach((evidence) => {
          this.updatedEvidence(evidence);
        });
      });
    this.optionData$
      .pipe(
        map((filterFn) => filterFn(this.strandId, this.level, this.coachlogId))
      )
      .subscribe((newData) => {
        this.optionList = newData;
        this.optionList.push(this.portfolioOnly);
        this.optionList.sort((a, b) => {
          if (a.content > b.content) {
            return 1;
          }
          if (a.content < b.content) {
            return -1;
          }
          return 0;
        });
      });
    this.coachingLogEvidenceService.evidenceId.subscribe((evidenceId) => {
      if (this.evidenceList.some((evidence) => evidence.id === evidenceId)) {
        this.isDrawerCollapsed = false;

        const evidenceDrawer = document.getElementById('drawer');
        evidenceDrawer?.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  ngAfterViewInit(): void {
    this.evidenceModal.isClosing.subscribe(() => {
      this.currentEvidence.coachlogOptionId = this.selectedOptionId ?? 0;
      if (this.newEvidence) {
        this.currentEvidence = {
          ...this.currentEvidence,
          coachlogOptionId: this.optionSelect.selectedValues[0].id,
        };
        this.createEvidence();
      } else {
        this.updateEvidence();
      }

      delete this.selectedOptionId;
    });
  }

  toggleCollapse() {
    if (this.evidenceList.length > 0) {
      this.isDrawerCollapsed = !this.isDrawerCollapsed;
    }
  }

  resetCurrentEvidence(): void {
    this.initialNarrativeContent = '';

    if (this.contentEditor) {
      this.contentEditor.resetContent();
    }

    this.currentEvidence = {
      id: 0,
      title: '',
      narrative: '',
      type: EvidenceType.NARRATIVE,
      userId: this.userId,
      rubricId: this.rubricId,
      level: this.level,
      competencyId: this.competencyId,
      coachlogId: this.coachlogId,
      coachlogOptionId: 0,
      strandId: this.strandId,
      addedFrom: 'coachlog',
      evidenceAttachments: [],
      assessments: [],
    };
    this.uploadType = null;
    this.checkEvidenceRequiredFields();
  }

  fileUploaded() {
    if (
      this.uploadType === UploadType.VIDEO &&
      this.uploadedFiles.length === 1 &&
      !this.newEvidence
    ) {
      this.evidenceService.addVideo(
        {
          objectId: this.currentEvidence.id,
          videoId: this.uploadedFiles[0].id,
          objectType: VideoReferenceObjectType.EVIDENCE,
        },
        this.currentEvidence
      );
    }
  }

  fileDeleted(file: FileDTO) {
    this.uploadedFiles.forEach((uploadedFile, index) => {
      if (uploadedFile.id === file.id) {
        this.uploadedFiles.splice(index, 1);
      }
    });
  }

  updatedEvidence(evidence: EvidenceDTO) {
    if (!evidence.coachlogOptionId) {
      evidence.coachlogOptionId = 0;
    }

    let oldOptionId: number | null = null;
    if (
      this.evidenceList.filter((haystack) => haystack.id === evidence.id)
        .length === 0
    ) {
      this.evidenceList.push(evidence);
    } else {
      this.evidenceList = this.evidenceList.map((haystack) => {
        if (haystack.id === evidence.id) {
          oldOptionId = haystack.coachlogOptionId;
          return evidence;
        }
        return haystack;
      });
    }
    // optionId can change without deleting evidence
    if (oldOptionId && oldOptionId !== evidence.coachlogOptionId) {
      this.removeEvidenceFromByOptionsList(evidence.id, oldOptionId);
    }
    if (!this.evidenceByOption[evidence.coachlogOptionId]) {
      // if option doesn't have any evidence
      this.evidenceByOption[evidence.coachlogOptionId] = [evidence];
    } else if (
      // if evidence doesn't already exist
      this.evidenceByOption[evidence.coachlogOptionId].filter(
        (haystack) => haystack.id === evidence.id
      ).length === 0
    ) {
      this.evidenceByOption[evidence.coachlogOptionId].push(evidence);
    } else {
      // update existing evidence
      const evidenceIndex = this.evidenceByOption[
        evidence.coachlogOptionId
      ].findIndex((haystack) => haystack.id === evidence.id);
      if (this.currentEvidence.id === evidence.id) {
        this.currentEvidence = evidence;
      }
      this.evidenceByOption[evidence.coachlogOptionId][evidenceIndex] =
        evidence;
    }
    this.evidenceByOption[evidence.coachlogOptionId].sort((a, b) =>
      // eslint-disable-next-line no-nested-ternary
      a.title.toLowerCase() < b.title.toLowerCase()
        ? -1
        : a.title.toLowerCase() > b.title.toLowerCase()
        ? 1
        : 0
    );
  }

  removeEvidenceFromByOptionsList(evidenceId: number, optionId: number) {
    this.evidenceByOption[optionId] = this.evidenceByOption[optionId].filter(
      (e) => e.id !== evidenceId
    );
    if (this.evidenceByOption[optionId].length === 0) {
      delete this.evidenceByOption[optionId];
    }
  }

  removedEvidence(evidence: EvidenceDTO) {
    this.evidenceList = this.evidenceList.filter((e) => e.id !== evidence.id);
    this.removeEvidenceFromByOptionsList(
      evidence.id,
      evidence.coachlogOptionId
    );
  }

  removeVideo() {
    this.evidenceService.removeVideo(this.currentEvidence);
    this.disableVideoRemoval = true;
    this.uploadedFiles = [];
  }

  createEvidence(): void {
    setTimeout(() => {
      this.evidenceService.addEvidence(
        this.currentEvidence,
        this.uploadedFiles
      );
    }, 500);
    this.isDrawerCollapsed = false;
  }

  updateEvidence(): void {
    setTimeout(() => {
      this.evidenceService.updateEvidence(this.currentEvidence).subscribe();
    }, 500);
  }

  addEvidence(): void {
    this.resetCurrentEvidence();
    this.initialNarrativeContent = '';
    this.newEvidence = true;
    this.evidenceModal.config.titleText = this.competencyTitle
      ? this.competencyTitle
      : 'Add Evidence';
    this.uploadedFiles.length = 0;
    this.evidenceModal.open();
  }

  editEvidence(id: number): void {
    this.disableVideoRemoval = false;
    this.newEvidence = false;
    this.evidenceModal.config.titleText = this.competencyTitle
      ? this.competencyTitle
      : 'Edit Evidence';
    this.currentEvidence = JSON.parse(
      JSON.stringify(this.evidenceList.find((e) => e.id === id))
    ) as EvidenceDTO;
    this.initialNarrativeContent = this.currentEvidence.narrative || '';
    this.checkEvidenceRequiredFields();
    this.uploadType =
      this.currentEvidence.type === EvidenceType.VIDEO
        ? UploadType.VIDEO
        : UploadType.EVIDENCE;
    this.selectedOptionId = this.currentEvidence.coachlogOptionId;
    this.uploadedFiles.length = 0;
    if (this.uploadComponent && this.uploadType === UploadType.EVIDENCE) {
      (this.uploadComponent.fileService as EvidenceFileService).setEvidenceId(
        this.currentEvidence.id
      );
    }
    if (this.currentEvidence.evidenceAttachments.length > 0) {
      this.currentEvidence.evidenceAttachments.forEach((e) =>
        this.uploadedFiles.push(fileDTOFromEvidenceAttachment(e))
      );
      this.numberOfFilesUploaded = this.uploadedFiles.length;
    }
    if (this.currentEvidence.videos && this.currentEvidence.videos.length > 0) {
      this.numberOfFilesUploaded = this.currentEvidence.videos.length;
    }
    this.evidenceModal.open();
  }

  openCopyEvidenceModal(evidenceId: number) {
    this.copyEvidenceModal.evidenceId = evidenceId;
    this.copyEvidenceModal.modal.open();
  }

  checkEvidenceRequiredFields() {
    const currentStatus: [boolean, boolean] = [
      this.hasNarrative,
      this.hasTitle,
    ];

    this.hasNarrative = this.currentEvidence.narrative.length > 0;
    this.hasTitle = this.currentEvidence.title.length > 0;
    const newStatus: [boolean, boolean] = [this.hasNarrative, this.hasTitle];

    if (
      currentStatus[0] !== newStatus[0] ||
      currentStatus[1] !== newStatus[1]
    ) {
      this.changeRef.detectChanges();
    }
  }

  changeEvidenceType() {
    this.currentEvidence.type =
      this.uploadType === UploadType.VIDEO
        ? EvidenceType.VIDEO
        : EvidenceType.NARRATIVE;
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  getOptionData(optionId: string): OptionDTO {
    return this.optionList.find(
      (option) => option.id === parseInt(optionId)
    ) as OptionDTO;
  }

  closeModalOnly() {
    this.evidenceModal.dismiss();
    delete this.selectedOptionId;
  }

  updateNarrative(narrative: string) {
    this.currentEvidence.narrative = narrative;
    this.checkEvidenceRequiredFields();
  }

  closeOnSubmit() {
    this.evidenceModal.close();
    this.numberOfFilesUploaded = 0;

    if (this.isSmart) {
      this.evidenceSelfAssessmentModal.modal.open();
    }
  }

  findSelectedOption() {
    this.selectedOption = this.optionList.filter(
      (option) => option.id === this.selectedOptionId
    )[0];
  }
}
