import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';

export interface CoachFeedbackRequestParams {
  search: string;
  per_page: number;
  page: number;
}

@Injectable({ providedIn: 'root' })
export class CoachFeedbackService {
  constructor(private apiService: APICoreService) {}

  getCoachFeedback(
    coachUserId: number,
    searchTerm: string,
    currentPage: number,
    perPage: number
  ) {
    const params = {
      search: searchTerm,
      per_page: perPage,
      page: currentPage,
    };
    return this.apiService
      .getRequest(`egrowe/coachlog/feedback/for-user/${coachUserId}`, params)
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  downloadCoachFeedbackCSV(userId: number) {
    return this.apiService.getRequest(
      `egrowe/coachlog/feedback/for-user/${userId}/download`
    );
  }
}
