<div class="container container-main">
  <app-dashboard-header
    *ngIf="user"
    [dashboardType]="user['type']"
    [showSwitcher]="false"
    [celebrateFromCoacheeList]="true"
    [selectedCoachees]="attendeeUserProfiles"></app-dashboard-header>
  <div class="card border-0 p-3 mt-3 table-container">
    <div
      class="tool position-absolute top-0 start-100 translate-middle rounded-circle fs-3"
      style="background-color: white; line-height: 1rem">
      <i
        [style.cursor]="'pointer'"
        routerLink="/dashboard"
        ngbTooltip="Close"
        class="bi bi-x-circle color-primary"></i>
    </div>
    <div class="row pb-4">
      <div
        class="col-md-6 col-xl-4 order-3 order-md-2 order-xl-1 justify-content-center justify-content-lg-start">
        <input
          type="search"
          class="form-control ms-1 coach-search"
          placeholder="Filter coaches"
          aria-label="Search"
          (input)="searchCoaches($event)" />
      </div>
      <div class="col-12 col-xl-4 order-1 order-xl-2 pb-3 text-center">
        <span class="h4">Current school year coaches</span>
      </div>
      <div
        class="col-md-6 col-xl-4 order-2 order-md-3 order-xl-3 d-flex align-items-center justify-content-center justify-content-lg-end py-3 py-md-0">
        <div>
          <ng-container *ngIf="selectedCoachIds.length > 9; else defaultButton">
            <button
              container="body"
              type="button"
              class="btn btn-primary mock-disabled"
              ngbTooltip="Maximum number of coachees reached (9). Remove some coaches to re-enable this button">
              <i class="bi bi-plus" style="padding: 0"></i> Create a Session
            </button>
          </ng-container>
          <ng-template #defaultButton>
            <button
              [disabled]="selectedCoachIds.length === 0"
              aria-label="Schedule session for coachees"
              type="button"
              container="body"
              ngbTooltip="Schedule session for coachees"
              class="btn btn-primary"
              (click)="createSessionParentModal.openModal()">
              <i class="bi bi-plus" style="padding: 0"></i> Create a Session
            </button>
          </ng-template>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading; else loadingTemplate" class="overflow-auto">
      <table *ngIf="tableData" class="table">
        <thead>
          <tr class="align-middle">
            <th class="text-center">
              <span class="visually-hidden">Select</span>
            </th>
            <th scope="col" sortable="name" (sort)="onSort($event)">
              Coaches ({{ totalCoaches }})
              <i class="bi bi-caret-down {{ sortIconTemp.name }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="avgfeedback"
              class="sortable-header text-center">
              Avg Feedback<br />Rating
              <i class="bi bi-caret-down {{ sortIconTemp.avgfeedback }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="avgconfidence"
              class="sortable-header text-center">
              Avg Coachee<br />Confidence
              <i class="bi bi-caret-down {{ sortIconTemp.avgconfidence }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="avggis"
              class="sortable-header text-center">
              Avg GIs<br />per Session
              <i class="bi bi-caret-down {{ sortIconTemp.avggis }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="avgbadges"
              class="sortable-header sortable-header-large text-center">
              Avg Badges<br />per Session
              <i class="bi bi-caret-down {{ sortIconTemp.avgbadges }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="avgsessions"
              class="sortable-header text-center">
              Avg Sessions<br />per Coachee
              <i class="bi bi-caret-down {{ sortIconTemp.avgsessions }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="celebrations"
              class="sortable-header text-center">
              Celebrations<br />Given
              <i class="bi bi-caret-down {{ sortIconTemp.celebrations }}"></i>
            </th>
            <th class="notes-header">Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let coach of tableData"
            style="border-bottom: 1px solid #78858e5a"
            class="align-middle">
            <td class="text-center">
              <input
                (change)="onCheckboxChange($event)"
                [(ngModel)]="coach.isSelected"
                class="form-check-input"
                type="checkbox"
                id="{{ 'checkbox' + coach.id }}"
                value="{{ coach.id }}"
                aria-label="select" />
            </td>
            <td>
              <label
                for="{{ 'checkbox' + coach.id }}"
                style="height: 6rem"
                class="d-flex justify-content-start align-items-center">
                <app-avatar
                  class="me-2"
                  [profile]="coach.profile"
                  [avatarSize]="'medium'">
                </app-avatar>
                {{ coach.profile.first_name }}
                {{ coach.profile.last_name }}
              </label>
            </td>
            <td class="text-center">
              <span class="stat-number">
                <ng-container
                  *ngIf="
                    coach.coachstats.average_feedback_rating > 0;
                    else noValue
                  ">
                  <app-basic-progress-bar
                    [progress]="
                      coach.coachstats.average_feedback_rating
                    " /> </ng-container
              ></span>
            </td>
            <td class="text-center">
              <span class="stat-number">
                <ng-container
                  *ngIf="
                    coach.coachstats.average_coachee_confidence_rating > 0;
                    else noValue
                  ">
                  <app-basic-progress-bar
                    [progress]="
                      coach.coachstats.average_coachee_confidence_rating
                    " /> </ng-container
              ></span>
            </td>
            <td class="text-center">
              <span class="stat-number">
                <ng-container
                  *ngIf="
                    coach.coachstats.average_gis_per_session;
                    else noValue
                  ">
                  {{ coach.coachstats.average_gis_per_session }}
                </ng-container>
              </span>
            </td>
            <td class="text-center">
              <span class="stat-number">
                <ng-container
                  *ngIf="
                    coach.coachstats.average_badges_per_session;
                    else noValue
                  ">
                  {{ coach.coachstats.average_badges_per_session }}
                </ng-container>
              </span>
            </td>
            <td class="text-center">
              <span class="stat-number">
                <ng-container
                  *ngIf="
                    coach.coachstats.average_sessions_per_coachee;
                    else noValue
                  ">
                  {{ coach.coachstats.average_sessions_per_coachee }}
                </ng-container>
              </span>
            </td>
            <td class="text-center">
              <span class="stat-number">
                <ng-container
                  *ngIf="coach.coachstats.celebrations_given; else noValue">
                  {{ coach.coachstats.celebrations_given }}
                </ng-container>
              </span>
            </td>
            <td class="notes notes-body">
              <app-coach-notes [coach]="coach"></app-coach-notes>
            </td>
          </tr>
        </tbody>
      </table>
      <ng-template #noValue> - </ng-template>
    </div>
    <ng-template #loadingTemplate>
      <app-loader></app-loader>
    </ng-template>
  </div>
</div>
<app-create-session-modal
  #createSessionParentModal
  [attendeeUserProfiles]="attendeeUserProfiles" />
