<div *ngIf="!hasData; else table">Loading...</div>
<ng-template #table>
  <table class="table">
    <thead>
      <tr>
        <th>Session Type</th>
        <th>Total Sessions</th>
        <th>Duration (hr)</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of displayData" class="frequency-table-row">
        <td>
          <span class="circle" style="background: {{ data.color }};"></span>
          {{ data.name }}
        </td>
        <td>{{ data.value }}</td>
        <td>
          {{ data.sessionDuration.hours }}h {{ data.sessionDuration.minutes }}m
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
