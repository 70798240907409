import { PrivateRoute, PublicRoute } from 'src/app/common/types/routes';
import {
  AUTH_GUARDED_ROUTE,
  EVERY_ROUTE,
} from 'src/app/common/utilities/route.helpers';
import { PageNotFoundComponent } from '../pages/404/404.component';
import { ChangePasswordRequestComponent } from '../pages/change-password-request/change-password-request.component';
import { ChangePasswordComponent } from '../pages/change-password/change-password.component';
import { CleverLoginComponent } from '../pages/clever-login/clever-login.component';
import { DataSharingComponent } from '../pages/data-sharing/data-sharing.component';
import { GoogleAdminCalendarComponent } from '../pages/google-admin-calendar/google-admin-calendar.component';
import { LoginComponent } from '../pages/login/login.component';
import { PlansPublicPageComponent } from '../pages/plans-public-page/plans-public-page.component';
import { PrivacyPolicyComponent } from '../pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from '../pages/terms/terms.component';
import { WelcomeComponent } from '../pages/welcome/welcome.component';

export const publicRoutes: Array<PublicRoute | PrivateRoute> = [
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'login',
    title: 'Login',
    component: LoginComponent,
  },
  {
    ...EVERY_ROUTE,
    path: 'change-password-request',
    title: 'Change Password Request',
    component: ChangePasswordRequestComponent,
  },
  {
    ...EVERY_ROUTE,
    path: 'change-password',
    title: 'Change Password',
    component: ChangePasswordComponent,
  },
  {
    ...EVERY_ROUTE,
    path: 'clever-auth',
    title: 'Clever Login',
    component: CleverLoginComponent,
  },
  {
    ...EVERY_ROUTE,
    path: 'welcome',
    title: 'Welcome',
    component: WelcomeComponent,
  },
  {
    ...EVERY_ROUTE,
    path: 'legal/privacy-policy',
    title: 'Privacy Policy',
    component: PrivacyPolicyComponent,
  },
  {
    ...EVERY_ROUTE,
    path: 'legal/terms',
    title: 'Terms',
    component: TermsComponent,
  },
  {
    ...EVERY_ROUTE,
    path: 'legal/data-sharing',
    title: 'Data Sharing Agreement',
    component: DataSharingComponent,
  },
  {
    ...EVERY_ROUTE,
    path: 'google-admin-calendar',
    title: 'Calendar Integration Setup',
    component: GoogleAdminCalendarComponent,
  },
  {
    ...EVERY_ROUTE,
    path: 'public-plan/:district-code/:slug',
    title: 'Implementation Plan',
    component: PlansPublicPageComponent,
  },
  {
    ...EVERY_ROUTE,
    path: '**',
    component: PageNotFoundComponent,
  },
];
