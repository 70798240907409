import { BooleanNumber } from '../utilities/enums/boolean-number.enum';
import { BaseEntity } from './abstract/base-entity.dto';
import { PhysicalLocation } from './abstract/physical-location.dto';
import { SchoolDTO } from './school.dto';

export interface DistrictDTO extends BaseEntity, PhysicalLocation {
  domain: string;
  hubspotCompanyId: string;
  districtCode: string;
  isB2b: number;
  isInternal: number;
  tenantId: number;
  parentDistrictId: number;
  logo: string;
  timezone: string;
  counts: {
    users: number;
    schools: number;
  };
  createdAt: number;
  updatedAt: number;
  schools?: SchoolDTO[];
  reportingSchoolYear: string;
  defaultPrivateLogs: boolean;
  users_can_login: number;
  nightlyDataExports: number;
}

export interface DistrictSimpleDto extends BaseEntity, PhysicalLocation {
  domain: string;
  hubspotCompanyId: string;
  districtCode: string;
  isB2b: number;
  isInternal: number;
  logo: string;
  timezone: string;
  reportingSchoolYear: string;
  defaultPrivateLogs: boolean;
  users_can_login: number;
  nightlyDataExports: number;
}

export interface DistrictFilterDtoApiResponse extends BaseEntity {
  is_archived: BooleanNumber;
  reporting_school_year: string;
  counts: {
    users: number;
    schools: number;
  };
  calendarBlocks: [];
}

export interface DistrictFilterDto extends BaseEntity {
  is_archived: BooleanNumber;
  reportingSchoolYear: string;
  counts: {
    users: number;
    schools: number;
  };
  calendarBlocks: [];
}

export interface CustomerDTO {
  hubspotCompanyId: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  timezone: string;
  domain: string;
  description: string;
  isArchived: boolean;
  licenseCount: string;
}

export interface DistrictPayloadDTO {
  title: string;
  domain: string;
  is_internal: number;
  is_b2b: number;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  logo: string;
  timezone: string;
  hubspot_company_id: string;
  district_code: string;
  data_contact_email: string;
  why: string;
  reporting_school_year: string;
  default_private_logs?: BooleanNumber;
  users_can_login: number;
  nightly_data_exports?: number;
}

export interface DistrictGoalDTO {
  id: number;
  title: string;
  is_deleted: boolean;
  description: string;
  default_value: string;
  created_at: number;
  updated_at: number;
  type: GoalType;
}

export enum GoalType {
  INPUT = 'input',
  OUTCOME = 'outcome',
}

export interface DistrictGoalItemDTO {
  id: number;
  is_deleted: number;
  school_year: string;
  district_id: number;
  district: DistrictDTO;
  goal_id: number;
  goal: DistrictGoalDTO;
  value: string;
  created_at: number;
  updated_at: number;
  july_value: string;
  august_value: string;
  september_value: string;
  october_value: string;
  november_value: string;
  december_value: string;
  january_value: string;
  february_value: string;
  march_value: string;
  april_value: string;
  may_value: string;
  june_value: string;
}

export interface DistrictEmailNotificationDTO {
  id: number;
  title: string;
  slug: string;
  sortOrder: number;
  isEnabled: BooleanNumber;
}

export interface EmailNotificationPayload {
  email_notification_id: number;
  is_enabled: number;
}

export interface UpdateEmailNotificationsPayload {
  email_notifications: EmailNotificationPayload[];
}
