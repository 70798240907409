import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthService } from 'src/app/common/services/auth/auth.service';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { SidebarRoute } from 'src/app/common/types/routes';
import {
  canAccessByFeatureFlag,
  canAccessByRole,
  checkRouteEnvironment,
} from 'src/app/common/utilities/check-route';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { sidebarRoutes } from '../../routes/sidebar.routes';
import { ImpersonateModalComponent } from '../../shared/components/modals/impersonate-modal/impersonate.modal.component';
import { UnleashService } from '../../shared/services/unleash/unleash.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output() readonly linkClicked: EventEmitter<unknown> = new EventEmitter();

  @ViewChild('impersonateModal') impersonateModal: ImpersonateModalComponent;

  isImpersonatingUser = false;

  Roles = UserRole;

  user$ = select(UserState.getUser);

  user: User;

  sidebarItems = sidebarRoutes;

  planEnabled = false;

  constructor(
    private store: Store,
    private authService: AuthService,
    private featureFlagService: UnleashService
  ) {
    this.isImpersonatingUser = this.store.selectSnapshot(
      (state) => state.user.impersonated
    );
  }

  ngOnInit(): void {
    this.user$.subscribe((res) => {
      if (res) this.user = res;
    });
  }

  linkSelected() {
    this.linkClicked.emit();
  }

  unimpersonate() {
    this.authService.stopImpersonatingUser();
  }

  shouldShowRoute = (route: SidebarRoute): boolean => {
    if (!checkRouteEnvironment(route.path || '')) {
      return false;
    }
    if (route.data.featureFlags) {
      return canAccessByFeatureFlag(route, this.featureFlagService);
    }
    return canAccessByRole(route, this.user);
  };

  logout() {
    this.authService.logoutAllAuthenticatedUsers();
  }
}
