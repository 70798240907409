/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'formatDateMMDDYY',
})
export class FormatDateMMDDYYPipe implements PipeTransform {
  transform(value: number, timezone?: string): string {
    return DateTime.fromSeconds(value, { zone: timezone || 'local' }).toFormat(
      'MM/dd/yy'
    );
  }
}
