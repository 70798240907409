import { Component, Input, OnChanges } from '@angular/core';
import { ECharts, EChartsOption } from 'echarts';

@Component({
  selector: 'app-chart-gauge',
  templateUrl: './chart-gauge.component.html',
  styleUrl: './chart-gauge.component.scss',
})
export class ChartGaugeComponent implements OnChanges {
  @Input() value: number;

  @Input() color = '#27004b';

  @Input() title = '';

  @Input() min_label = '';

  @Input() max_label = '';

  eChartsInstance: ECharts;

  chartOptions: EChartsOption;

  isLoading = true;

  spinnerOptions = {
    text: 'Loading...',
    textColor: '#27004b',
    maskColor: 'rgba(255, 255, 255)',
    zlevel: 10,
    fontSize: 24,
    fontFamily: 'greycliff-cf',
    showSpinner: false,
  };

  ngOnChanges(): void {
    if (this.value && this.color) {
      this.chartOptions = {
        grid: {
          show: false,
          top: 0,
          borderWidth: 2,
        },
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 4,
            name: this.title,
            splitNumber: 4,
            itemStyle: {
              color: this.color,
            },
            progress: {
              show: true,
              roundCap: false,
              width: 20,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              roundCap: false,
              lineStyle: {
                width: 20,
                color: [[1, '#F0EEE9']],
              },
            },
            axisTick: {
              show: true,
              splitNumber: 1,
              lineStyle: {
                width: 1,
                color: '#999',
              },
            },
            splitLine: {
              show: false,
              length: 4,
              lineStyle: {
                width: 3,
                color: '#999',
              },
            },
            axisLabel: {
              show: false,
              distance: 13,
              color: '#999',
              fontSize: 8,
            },
            title: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: this.value,
              },
            ],
          },
        ],
      };
      this.isLoading = false;
    }
  }
}
