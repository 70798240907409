<div class="card border-0">
  <div class="card-header pt-3">
    <h2
      id="todo-title"
      class="card-title float-start"
      (click)="triggerCollapse()">
      Coaching Quick Stats
      <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
    </h2>
    <div class="card-tools float-end">
      <a routerLink="/dashboard/coach-list" class="text-primary">
        See All
        <i class="ps-1 bi bi-box-arrow-up-right"></i>
      </a>
    </div>
  </div>
  <div collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div
      class="card-body pt-0"
      tabindex="0"
      role="group"
      aria-labelledby="todo-title">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-3">
          <ng-container *ngIf="hasAvgCoacheeConfidence; else noValue">
            <app-chart-gauge
              [value]="avgFeedbackRating"
              [min_label]="'Ineffective'"
              [max_label]="'Game Changer'"
              [color]="'#AF4F9D'"
              [title]="'Effectiveness'" />
          </ng-container>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <ng-container *ngIf="hasAvgCoacheeConfidence; else noValue">
            <app-chart-gauge
              [value]="avgCoacheeConfidence"
              [min_label]="'Doubtful'"
              [max_label]="'Confident'"
              [color]="'#267DAD'"
              [title]="'Confidence'" />
          </ng-container>
        </div>
        <div class="col-6 col-lg-3">
          <div class="stat-item text-center fw-800">
            <div class="stat color-level-3">
              <ng-container *ngIf="hasAvgSessionsPerCoach; else noValue">
                {{ avgSessionsPerCoach }}
              </ng-container>
            </div>
            <div class="fs-15 fw-800 mb-1">
              Average Sessions<br />
              per Coachee
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-3">
          <div class="stat-item text-center fw-800">
            <div class="stat color-gold">
              <ng-container *ngIf="hasAvgBadgesPerSession; else noValue">
                {{ avgBadgesPerSession }}
              </ng-container>
            </div>
            <div class="fs-15 fw-800 mb-1">
              Average Badges<br />
              per Session
            </div>
          </div>
        </div>
      </div>
      <ng-template #noValue> - </ng-template>
    </div>
  </div>
</div>
