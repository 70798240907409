import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';

import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { PlansService } from '../../../services/plans/plans.service';
import { PlanDetailsItemStateModel } from '../../../state/implementation-plan/implementation-plan.state';
import {
  CreateStatusPlanPayload,
  EmailPreviewPayload,
} from '../../../types/payloads/plan.payload';
import { ModalComponent } from '../../modals/modal/modal.component';
import { FroalaOptions } from '../../wysiwyg-editor/wysiwyg-editor.component';

@Component({
  selector: 'app-create-report-modal',
  templateUrl: './create-report-modal.component.html',
  styleUrl: './create-report-modal.component.scss',
})
export class CreateReportModalComponent implements OnInit {
  @Input() plan: PlanDetailsItemStateModel;

  @Output() readonly reportCreated = new EventEmitter<boolean>();

  @ViewChild('modal') modal: ModalComponent;

  user: User;

  isLoading = false;

  activeSection: 'form' | 'preview';

  emailRecipients = '';

  emailBody: string;

  initialHighlightsContent = '';

  highlights: string;

  currentDateTime = new Date().getTime() / 1000;

  errorMessage: string;

  froalaOptions: FroalaOptions;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private froalaControls: any;

  defaultFroalaOptions: FroalaOptions = {
    attribution: false,
    key: EnvironmentService.froalaKey,
    charCounterCount: true,
    inlineMode: false,
    quickInsertEnabled: false,
    imageUploadURL: `${EnvironmentService.apiUrl()}/uploads`,
    toolbarButtons: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'fontFamily',
          'fontSize',
          'textColor',
          'backgroundColor',
          'strikeThrough',
          'subscript',
          'superscript',
          'clearFormatting',
        ],
      },
      moreParagraph: {
        buttons: [
          'alignLeft',
          'alignCenter',
          'alignRight',
          'formatOL',
          'formatUL',
          'paragraphFormat',
          'paragraphStyle',
          'lineHeight',
          'outdent',
          'indent',
          'quote',
        ],
        buttonsVisible: 4,
      },
      moreRich: {
        buttons: [
          'emoticons',
          'specialCharacters',
          'insertLink',
          'insertTable',
          'insertImage',
          'insertHR',
        ],
        buttonsVisible: 1,
      },
      moreMisc: {
        buttons: [
          'undo',
          'redo',
          'fullscreen',
          'print',
          'spellChecker',
          'selectAll',
        ],
        buttonsVisible: 2,
      },
    },
    editorClass: 'full',
  };

  constructor(private plansService: PlansService, private store: Store) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.activeSection = 'form';

    this.froalaOptions = {
      ...this.defaultFroalaOptions,
      events: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        'image.uploaded': (res: any) => {
          const imgUrl = `${EnvironmentService.apiUrl()}${
            JSON.parse(res).location
          }`;
          this.froalaControls.image.insert(
            imgUrl,
            true,
            null,
            this.froalaControls.image.get(),
            { link: JSON.parse(res).location }
          );
          return false;
        },
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initializeLink(controls: any) {
    controls.initialize();
    this.froalaControls = controls.getEditor();
  }

  openModal() {
    this.modal.open();
  }

  closeModal() {
    this.resetForm();
    this.modal.close();
  }

  changeSection(sectionName: 'form' | 'preview') {
    if (sectionName === 'form') {
      this.errorMessage = '';
    }
    this.activeSection = sectionName;
  }

  updateHighlights(newNotes: string) {
    this.highlights = newNotes;
  }

  resetForm() {
    this.emailRecipients = '';
    this.highlights = '';
    this.emailBody = '';
    this.activeSection = 'form';
    this.errorMessage = '';
  }

  getEmailPreview() {
    this.isLoading = true;
    const payload: EmailPreviewPayload = {
      highlights: this.highlights,
    };

    this.plansService.previewEmail(this.plan.id, payload).subscribe((res) => {
      if (res.item) {
        this.isLoading = false;
        this.emailBody = res.item;
        this.activeSection = 'preview';
      }
    });
  }

  saveReportData() {
    this.isLoading = true;

    const submitReportPayload: CreateStatusPlanPayload = {
      date: Math.trunc(this.currentDateTime),
      user_id: this.user.id,
      send_to: this.emailRecipients,
      highlights: this.highlights,
      email_body: this.emailBody,
    };

    this.plansService
      .createStatusReport(this.plan.id, submitReportPayload)
      .subscribe({
        error: (err) => {
          this.isLoading = false;
          this.errorMessage = err.error.message;
        },
        complete: () => {
          this.isLoading = false;
          this.activeSection = 'form';
          this.reportCreated.next(true);
          this.closeModal();
        },
      });
  }
}
