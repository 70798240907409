<div class="container container-main">
  <app-general-header [title]="'Implementation Plans'" />

  <div class="card border-0 p-3 px-lg-5 py-lg-4" style="min-height: 500px">
    <div class="row d-flex justify-content-between my-3">
      <div class="col-12 col-md-6 order-2 order-md-1">
        <div class="table-search">
          <input
            #searchText
            type="search"
            class="form-control"
            placeholder="Search"
            aria-label="Search"
            (input)="searchTermChanged($event)" />
        </div>
      </div>
      <div
        class="d-flex col-12 col-md-6 order-1 order-md-2 justify-content-center justify-content-md-end mb-3 my-md-0">
        <button
          *ngIf="planEditor"
          class="btn btn-primary btn-create-plan"
          (click)="openCreateEditPlanModal()">
          <i class="bi bi-plus"></i>
          Create New Plan
        </button>
      </div>
    </div>

    <ng-container *ngIf="tableData">
      <div class="table-wrapper w-100">
        <table class="table">
          <thead>
            <tr>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="title"
                class="plans">
                Plan
                <ng-container *ngIf="!loadingIcons">
                  <i class="bi bi-caret-down {{ sortIconTemp.title }}"></i>
                </ng-container>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="description"
                class="description">
                Description
                <ng-container *ngIf="!loadingIcons">
                  <i
                    class="bi bi-caret-down {{ sortIconTemp.description }}"></i>
                </ng-container>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="start_date"
                class="mscZ">
                Dates
                <ng-container *ngIf="!loadingIcons">
                  <i class="bi bi-caret-down {{ sortIconTemp.start_date }}"></i>
                </ng-container>
              </th>
              <th scope="col" class="prog">Progress</th>
              <th scope="col" class="edit"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="!plansLoading; else loadingTemplate">
              <ng-container *ngIf="tableData.length > 0; else noPlans">
                <tr *ngFor="let plan of tableData">
                  <td>
                    <a
                      class="link cursor-pointer"
                      [routerLink]="
                        '/plans/implementation-plan/' + plan.id + '/dashboard'
                      "
                      ><strong>{{ plan.title }}</strong></a
                    >
                    <div *ngIf="isB2b || planEditor" class="fs-13 mt-1">
                      {{ plan.district.title }}
                    </div>
                  </td>
                  <td>{{ plan.description }}</td>
                  <td>
                    {{ plan.start_date | date : "M/dd/yyyy" }} -
                    {{ plan.end_date | date : "M/dd/yyyy" }}
                  </td>
                  <td>
                    <app-plan-progress-bar [progress]="plan.plan_status" />
                  </td>
                  <td class="text-center">
                    <i
                      *ngIf="planEditor"
                      ngbTooltip="Edit Plan"
                      class="bi bi-pencil cursor-pointer color-tertiary"
                      (click)="openCreateEditPlanModal(plan)"></i>
                  </td>
                </tr>
              </ng-container>
              <ng-template #noPlans>
                <tr>
                  <td colspan="5">
                    <div
                      class="d-flex flex-column align-items-center justify-content-center w-100"
                      style="min-height: 400px">
                      <h2>No Plans Found</h2>
                      <p>Please create a plan</p>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </ng-container>
          </tbody>
        </table>
        <section>
          <div class="d-flex justify-content-center">
            <ngb-pagination
              *ngIf="plansMeta && plansMeta.totalCount > 10"
              [(page)]="plansMeta.currentPage"
              [pageSize]="plansMeta.perPage"
              [collectionSize]="plansMeta.totalCount"
              [maxSize]="5"
              (pageChange)="paginationChanges($event)"></ngb-pagination>
          </div>
        </section>
      </div>
    </ng-container>
  </div>
</div>
<app-create-edit-plan-modal
  #createEditPlanModal
  [user]="user"
  [editPlanData]="currentEditPlan"
  (planEvent)="getPlans()"
  (resetEditPlanData)="currentEditPlan = null" />
<ng-template #loadingTemplate>
  <div class="w-100" style="height: 700px">
    <app-loader></app-loader>
  </div>
</ng-template>
