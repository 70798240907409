import { SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import { User } from 'src/app/common/state/user/user.model';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';

export const schoolYearList: SchoolYearDTO[] = [
  {
    id: 1,
    name: '2014-2015',
    start_date: '2014-07-01',
    end_date: '2015-06-30',
  },
  {
    id: 2,
    name: '2015-2016',
    start_date: '2015-07-01',
    end_date: '2016-06-30',
  },
  {
    id: 3,
    name: '2016-2017',
    start_date: '2016-07-01',
    end_date: '2017-06-30',
  },
  {
    id: 4,
    name: '2017-2018',
    start_date: '2017-07-01',
    end_date: '2018-06-30',
  },
  {
    id: 5,
    name: '2018-2019',
    start_date: '2018-07-01',
    end_date: '2019-06-30',
  },
  {
    id: 6,
    name: '2019-2020',
    start_date: '2019-07-01',
    end_date: '2020-06-30',
  },
  {
    id: 7,
    name: '2020-2021',
    start_date: '2020-07-01',
    end_date: '2021-06-30',
  },
  {
    id: 8,
    name: '2021-2022',
    start_date: '2021-07-01',
    end_date: '2022-06-30',
  },
  {
    id: 9,
    name: '2022-2023',
    start_date: '2022-07-01',
    end_date: '2023-06-30',
  },
  {
    id: 10,
    name: '2023-2024',
    start_date: '2023-07-01',
    end_date: '2024-06-30',
  },
  {
    id: 11,
    name: '2024-2025',
    start_date: '2024-07-01',
    end_date: '2025-06-30',
  },
];

export const CURRENT_SCHOOL_YEAR: SchoolYearDTO =
  schoolYearList[schoolYearList.length - 1];

export const getDistrictSchoolYearFromUser = (user: User): SchoolYearDTO =>
  schoolYearList.find(
    (year) => year.name === user?.district?.reportingSchoolYear
  ) ??
  (schoolYearList.find(
    (year) => year.id === CURRENT_SCHOOL_YEAR.id
  ) as SchoolYearDTO);

export const getSchoolYearListWithFutures = (
  futureYears: number
): SchoolYearDTO[] => {
  const newSchoolYearList = deepCopy(schoolYearList);
  for (let i = 0; i < futureYears; i += 1) {
    const lastIndexYear = newSchoolYearList[newSchoolYearList.length - 1];
    let [startYear, endYear] = lastIndexYear.name.split('-').map(Number);
    startYear += 1;
    endYear += 1;
    newSchoolYearList.push({
      id: lastIndexYear.id + 1,
      name: `${startYear}-${endYear}`,
      start_date: `${startYear}-07-01`,
      end_date: `${endYear}-06-30`,
    });
  }

  return newSchoolYearList;
};
