<div class="container">
  <app-general-header title="Implementation Plan" />
  <a routerLink="/plans" class="text-decoration-none text-body-secondary"
    >&lt; Back to Plans</a
  >
  <ng-container *ngIf="planDetails">
    <ng-container *ngIf="!planDetails.forbidden; else deniedMessage">
      <div class="row mt-2 mb-4">
        <div *ngIf="planDetails?.title; else noTitle" class="col text-center">
          <div class="d-flex justify-content-center align-items-center">
            <h1 class="fw-600">
              {{ planDetails.title }}
            </h1>
          </div>
          <div>
            {{ planDetails.start_date }} -
            {{ planDetails.end_date }}
          </div>
        </div>
        <ng-template #noTitle>
          <div style="height: 7.5rem"></div>
        </ng-template>
      </div>

      <div class="card bg-none border-0">
        <app-plans-subnav [planId]="planId"></app-plans-subnav>
        <div class="card-body bg-white dashboard-card">
          <ng-container *ngIf="planSharing; else loading">
            <h2 class="fs-4 fw-bold mb-1">Sharing Options</h2>
            <p class="mb-4">
              Style your public-facing plan page and click save to publish.
            </p>
            <div
              class="form-check form-switch d-flex align-items-center mt-3 p-0">
              <button
                aria-label="sharing-enabled-button"
                class="form-switch btn-reset">
                <input
                  id="sharing-enabled-button"
                  class="form-check-input cursor-pointer"
                  type="checkbox"
                  role="switch"
                  [(ngModel)]="sharingEnabled"
                  (ngModelChange)="onFormChange()" />
              </button>
              <label class="form-check-label fw-bold" for="attendance-toggle">
                Enable Sharing
              </label>
            </div>
            <ng-container *ngIf="planSharing.is_enabled">
              <div class="p-2 d-flex align-items-center">
                {{ sharingUrl }}
                <button
                  ngbTooltip="{{ copyTooltipMessage }}"
                  container="body"
                  #t="ngbTooltip"
                  (click)="copyUrl(t)"
                  class="btn btn-link color-tertiary ps-3 p-2">
                  <i class="bi bi-copy"></i>
                </button>
                <button
                  ngbTooltip="View Public Page"
                  (click)="viewPublicPage()"
                  class="btn btn-link color-tertiary p-2">
                  <i class="bi bi-box-arrow-up-right"></i>
                </button>
              </div>
            </ng-container>
            <h2 class="fs-4 fw-bold mb-3 mt-4">Page Design</h2>
            <div class="row mb-4">
              <div class="col-12 col-lg-6 col-xl-4 mb-3 mb-lg-0">
                <p class="fw-bold mb-2">Logo:</p>
                <app-file-upload
                  #uploadComponent
                  [disabled]="attachments.length > 0"
                  [style]="uploadStyle"
                  [allowedFileTypes]="allowedFileTypes"
                  (outputFiles)="updateAttachments($event); onFormChange()"
                  [outputRaw]="true"
                  (outputRawFiles)="updateLogo($event)" />
                <app-file-list
                  *ngIf="attachments.length > 0"
                  [skipApiCallOnDelete]="true"
                  [fileList]="attachments"
                  (deletedFile)="deleteAttachment($event); onFormChange()" />
              </div>
              <div class="col-12 col-lg-6 col-xl-5 col-xxl-4 px-4 mb-3 mb-lg-0">
                <div class="row">
                  <div class="col">
                    <p class="fw-bold mb-2">Dark Color:</p>
                    <div class="color-picker-container">
                      <input
                        type="color"
                        [(ngModel)]="planSharing.dark_color"
                        (change)="updateColor($event, 'dark'); onFormChange()"
                        class="color-picker" />
                      <span class="color-picker-text text-white">Abc</span>
                    </div>
                  </div>
                  <div class="col">
                    <p class="fw-bold mb-2">Light Color:</p>
                    <div class="color-picker-container">
                      <input
                        type="color"
                        [(ngModel)]="planSharing.light_color"
                        (change)="updateColor($event, 'light'); onFormChange()"
                        class="color-picker" />
                      <span class="color-picker-text">Abc</span>
                    </div>
                  </div>
                  <div class="col">
                    <p class="fw-bold mb-2" style="white-space: nowrap">
                      Background Color:
                    </p>
                    <div class="color-picker-container">
                      <input
                        type="color"
                        [(ngModel)]="planSharing.background_color"
                        (change)="
                          updateColor($event, 'background'); onFormChange()
                        "
                        class="color-picker" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h2 class="fs-4 fw-bold mb-3">Plan Details</h2>
                <p class="fw-bold mb-2">Header Content</p>
                <app-wysiwyg-editor
                  #editor
                  [config]="{
                    initialContent: planSharing.header_content || undefined
                  }"
                  (isSavingChange)="onFormChange()" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p class="fw-bold mb-2">Plan Content</p>
                <p>Select which items appear on the public share page.</p>
                <div class="form-check mb-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="show-phases-checkbox"
                    [disabled]="true"
                    checked />
                  <label class="form-check-label" for="show-phases-checkbox">
                    {{ planDetails.phase_displayname }}
                  </label>
                </div>
                <div class="form-check">
                  <input
                    [(ngModel)]="deliverablesEnabled"
                    (ngModelChange)="onFormChange()"
                    class="form-check-input"
                    type="checkbox"
                    id="show-deliverables-checkbox" />
                  <label
                    class="form-check-label"
                    for="show-deliverables-checkbox">
                    {{ planDetails.deliverable_displayname }}
                  </label>
                </div>
              </div>
            </div>
            <button
              [disabled]="isLoading || !formChanged"
              type="button"
              class="btn btn-md btn-primary float-end mt-4"
              (click)="submit()">
              <ng-container *ngIf="isLoading">
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </ng-container>
              Save
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <div
      class="w-100 position-relative mt-3"
      style="background-color: #fff; height: 600px">
      <app-loader></app-loader>
    </div>
  </ng-template>
  <ng-template #deniedMessage>
    <div class="mt-5">
      <app-permission-denied />
    </div>
  </ng-template>
</div>
