<div class="container">
  <app-general-header title="Coaching Sessions" />
  <div class="card border-0">
    <div class="card-body p-3">
      <ng-container *ngIf="sessionLoaded; else loadingTemplate">
        <ng-container *ngIf="sessionData; else sessionErrorMessage">
          <ng-container *ngIf="hasPresentAttendee; else noAttendeeTemplate">
            <ng-container [ngSwitch]="currentScreen">
              <ng-container *ngSwitchCase="'selectCompetencies'">
                <div class="session-nav-bar">
                  <a
                    routerLink="/coaching/log/{{ sessionId }}/info"
                    class="text-decoration-none text-body-secondary">
                    < Back to Session Info
                  </a>
                </div>
                <h2 class="text-center session-title">
                  {{ sessionData.title }}
                </h2>
                <div
                  class="d-flex flex-wrap justify-content-between align-items-center mb-3">
                  <ng-template
                    [ngIf]="
                      coachingService.logPusherService.attendeeRosters[
                        sessionId
                      ].length > 0
                    ">
                    <div class="d-flex flex-row align-items-center">
                      <div class="me-3 text-body-secondary">
                        Current Viewers
                      </div>
                      <app-avatar
                        style="margin-left: -5px"
                        avatarSize="small"
                        *ngFor="let attendee of pusherAttendees"
                        [userId]="attendee.id"
                        [avatarData]="attendee.avatar"
                        [grayScale]="!attendee.samePage"
                        data-testid="attendee-avatar"></app-avatar>
                    </div>
                  </ng-template>
                  <div class="col text-end">
                    <button
                      (click)="navigateNextScreen()"
                      [disabled]="
                        !competencySelected || competencySelectionLoading
                      "
                      ngbTooltip="At least 1 competency must be selected."
                      [disableTooltip]="competencySelected"
                      style="pointer-events: auto"
                      class="btn btn-primary btn-next">
                      Let's Growe!
                      <i
                        class="bi {{
                          !competencySelectionLoading
                            ? 'bi-arrow-right'
                            : 'bi-arrow-clockwise spin'
                        }}"></i>
                    </button>
                  </div>
                </div>
                <app-select-competencies-screen
                  (hasCompetencySelected)="handleCompetencySelectOutput($event)"
                  (isLoading)="handleCompetencySelectLoading($event)"
                  [attendeeIds]="attendeeIds"
                  [logId]="sessionId" />
              </ng-container>
              <ng-container *ngSwitchCase="'groweCoaching'">
                <div class="session-nav-bar">
                  <a
                    routerLink="/coaching/log/{{ sessionId }}/competencies"
                    class="text-decoration-none text-body-secondary">
                    < Back to Select Competencies
                  </a>
                </div>
                <h2 class="text-center session-title">
                  {{ sessionData.title }}
                </h2>
                <div
                  class="d-flex flex-wrap justify-content-between align-items-center mb-3">
                  <ng-template
                    [ngIf]="
                      coachingService.logPusherService.attendeeRosters[
                        sessionId
                      ].length > 0
                    ">
                    <div class="d-flex flex-row align-items-center">
                      <div class="me-3 text-body-secondary">
                        Current Viewers
                      </div>
                      <app-avatar
                        style="margin-left: -5px"
                        avatarSize="small"
                        *ngFor="let attendee of pusherAttendees"
                        [userId]="attendee.id"
                        [avatarData]="attendee.avatar"
                        [grayScale]="!attendee.samePage"
                        data-testid="attendee-avatar"></app-avatar>
                    </div>
                  </ng-template>
                  <div class="col text-end">
                    <button
                      [disabled]="rubricIsLoading"
                      routerLink="/coaching/log/{{ sessionId }}/summary"
                      class="btn btn-primary btn-next">
                      Complete
                      <i class="bi bi-arrow-right"></i>
                    </button>
                  </div>
                </div>
                <ng-container *ngIf="!rubricIsLoading; else loadingTemplate">
                  <label
                    class="visually-hidden"
                    [attr.for]="'log-top-attendee-select'"
                    >Select Participants</label
                  >
                  <div class="text-center">
                    <div class="mb-3" style="max-width: 350px; margin: 0 auto">
                      <ng-container
                        *ngIf="
                          !loggedInAsAttendee &&
                          sessionData.attendees.length > 1
                        ">
                        <ng-select
                          [labelForId]="'log-top-attendee-select'"
                          [items]="attendeeUserDTOs"
                          [searchable]="false"
                          [clearable]="false"
                          [placeholder]="'Select Coachee'"
                          [(ngModel)]="selectedAttendee"
                          #select>
                          <ng-template ng-label-tmp let-item="item">
                            <span class="fw-800">Coachee:</span>
                            &nbsp;<app-users-name
                              [userProfile]="item.profile" />
                          </ng-template>
                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index"
                            let-search="term">
                            <app-users-name [userProfile]="item.profile" />
                          </ng-template>
                        </ng-select>
                      </ng-container>
                    </div>
                  </div>
                  <ng-container *ngFor="let attendeeRubric of rubricData">
                    <ng-container
                      *ngIf="
                        (!loggedInAsAttendee &&
                          selectedAttendee &&
                          selectedAttendee.id === attendeeRubric.userId) ||
                        attendeeRubric.userId == user?.id
                      ">
                      <ng-container
                        *ngFor="let rubric of attendeeRubric.rubrics">
                        <ng-container
                          *ngFor="
                            let attendeeCompetencyGroup of rubric.competencyGroups
                          ">
                          <ng-container
                            *ngFor="
                              let attendeeCompetency of attendeeCompetencyGroup.competencies
                            ">
                            <br />
                            <app-leveled-competency
                              [userId]="attendeeRubric.userId"
                              [competency]="attendeeCompetency"
                              [rubricId]="rubric.id"
                              [logId]="sessionId" />
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="evidenceIdParam">
                    <app-evidence-summary
                      [evidenceId]="evidenceIdParam"
                      [sessionId]="sessionId"
                      [autoOpenModal]="true"></app-evidence-summary>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'sessionSummary'">
                <div class="session-nav-bar">
                  <a
                    routerLink="/coaching/log/{{ sessionId }}/growth"
                    class="text-decoration-none text-body-secondary">
                    < Back to Coaching
                  </a>
                </div>
                <h2 class="text-center session-title">
                  {{ sessionData.title }}
                </h2>
                <div
                  class="d-flex flex-wrap justify-content-between align-items-center mb-3">
                  <ng-template
                    [ngIf]="
                      coachingService.logPusherService.attendeeRosters[
                        sessionId
                      ].length > 0
                    ">
                    <div class="d-flex flex-row align-items-center">
                      <div class="me-3 text-body-secondary">
                        Current Viewers
                      </div>
                      <app-avatar
                        style="margin-left: -5px"
                        avatarSize="small"
                        *ngFor="let attendee of pusherAttendees"
                        [userId]="attendee.id"
                        [avatarData]="attendee.avatar"
                        [grayScale]="!attendee.samePage"
                        data-testid="attendee-avatar"></app-avatar>
                    </div>
                  </ng-template>
                </div>
                <app-coaching-session-summary
                  [session]="sessionData"
                  [rubricData]="rubricData"></app-coaching-session-summary>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #noAttendeeTemplate>
            <p>
              <ngb-alert [dismissible]="false">
                <strong>No Present Participants</strong> At least 1 participant
                must be marked present.
                <a
                  routerLink="/coaching/log/{{ sessionId }}/info"
                  class="text-decoration-none text-body-secondary"
                  ><br />
                  &lt; back
                </a>
              </ngb-alert>
            </p>
          </ng-template>
        </ng-container>
        <ng-template #sessionErrorMessage>
          <ng-container *ngIf="coachLogStatus && coachLogStatus.errors">
            <app-error-message [message]="coachLogStatus.errors[0]" />
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-template #loadingTemplate>
        <app-loader></app-loader>
      </ng-template>
    </div>
    <div
      role="button"
      (click)="toggleNotes()"
      class="d-none d-sm-block border border-2 border-end-0 border-secondary rounded-start notes-fade-out notes-tab {{
        notesTabActive ? '' : 'hide'
      }} ">
      <div class="d-flex align-items-center" @fadeInOut>
        <i class="bi bi-journal journal-icon"></i>
        <p class="notes-title">Notes</p>
      </div>
    </div>
    <div
      *ngIf="sessionData"
      role="button"
      (click)="toggleCompletion()"
      class="d-none d-sm-block border border-2 border-end-0 border-secondary rounded-start completion-fade-out completion {{
        completionTabActive ? '' : 'hide'
      }} ">
      <div class="d-flex align-items-center pt-1">
        <i class="bi bi-shield-check journal-icon mx-2" @fadeInOut></i>
        <div @delayfadeInOut>
          <div class="fs-16 fw-800">{{ percentComplete }}% Complete</div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="sessionData">
      <app-coaching-log-notes
        (hideNotesEvent)="toggleNotes()"
        [user]="user"
        [sessionId]="sessionId"
        [notesActive]="notesActive" />
      <app-coaching-log-completion
        (hideCompletionEvent)="toggleCompletion()"
        [completionActive]="completionActive"
        [sessionId]="sessionId"
        (percentageCalculationEvent)="updatePercentComplete($event)" />
    </ng-container>
  </div>
  <app-badge-earned-modal
    [currentUsersRole]="sessionData?.currentUsersRole || null" />
  <app-resource-search-modal />
</div>
