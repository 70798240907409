<app-modal
  #createEditPlanModal
  [modalConfig]="{
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    showFooter: false
  }"
  (isClosing)="clearForm()"
  (isDismissing)="clearForm()">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="createEditPlanModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <ng-container *ngIf="form">
    <form [formGroup]="form">
      <label for="plan-title" class="form-label">Title:</label>
      <div class="input-group mb-2">
        <input
          formControlName="title"
          type="text"
          class="form-control"
          id="plan-title"
          aria-label="Plan Title"
          [ngClass]="{
            'is-invalid': formSubmitted && f['title'].errors
          }" />
      </div>
      <label for="plan-audience" class="form-label">Description:</label>
      <div class="input-group mb-2">
        <textarea
          formControlName="description"
          type="text"
          class="form-control"
          id="plan-description"
          aria-label="Plan Description"
          [ngClass]="{
            'is-invalid': formSubmitted && f['description'].errors
          }"></textarea>
      </div>

      <label for="plan-naming-convention" class="form-label"
        >Hierarchy Naming Convention:</label
      >
      <div class="row">
        <div class="col-6">
          <ng-select
            [clearable]="false"
            formControlName="level1"
            id="plan-level1"
            aria-label="Plan level1"
            [ngClass]="{
              'is-invalid': formSubmitted && f['level1'].errors
            }">
            <ng-option value="Phase">Phase</ng-option>
            <ng-option value="Goal">Goal</ng-option>
            <ng-option value="Strategy">Strategy</ng-option>
            <ng-option value="Gate">Gate</ng-option>
            <ng-option value="Step">Step</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row my-3 align-items-center">
        <div class="col-1 offset-1">
          <i class="bi bi-arrow-return-right me-3"></i>
        </div>
        <div class="col-6">
          <ng-select
            [clearable]="false"
            formControlName="level2"
            id="plan-level2"
            aria-label="Plan level2"
            [ngClass]="{
              'is-invalid': formSubmitted && f['level2'].errors
            }">
            <ng-option value="Deliverable">Deliverable</ng-option>
            <ng-option value="Specific Result">Specific Result</ng-option>
            <ng-option value="Objective">Objective</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row mb-2 align-items-center">
        <div class="col-1 offset-3">
          <i class="bi bi-arrow-return-right me-3"></i>
        </div>
        <div class="col-6">
          <ng-select
            [clearable]="false"
            formControlName="level3"
            id="plan-level3"
            aria-label="Plan level3"
            [ngClass]="{
              'is-invalid': formSubmitted && f['level3'].errors
            }">
            <ng-option value="Action Item">Action Item</ng-option>
          </ng-select>
        </div>
      </div>
      <ng-container *ngIf="isE2l && user.district && !editPlanData">
        <div class="row">
          <label for="district-select" class="form-label">District:</label>
          <div class="col-6 mb-2">
            <app-district-select
              #districtSelect
              (selectedDistrict)="updateDistrict($event)"
              [defaultDistricts]="[user.district.id]"
              [simpleSelect]="true" />
          </div>
        </div>
      </ng-container>
      <div class="row">
        <div class="col">
          <label class="form-label" for="plan-start-date">Start Date:</label>
          <app-datepicker
            #startDatePicker
            id="plan-start-date"
            [initialDateUnixTimestamp]="defaultStartDate"
            (dateSelectedUnixTimestamp)="updateStartDate($event)" />
        </div>
        <div class="col">
          <label class="form-label" for="plan-end-date">End Date:</label>
          <app-datepicker
            #endDatePicker
            id="plan-end-date"
            [initialDateUnixTimestamp]="defaultEndDate"
            (dateSelectedUnixTimestamp)="updateEndDate($event)" />
        </div>
      </div>

      <div *ngIf="serverError" class="mt-3">
        <ngb-alert [dismissible]="false" [type]="'danger'">
          <strong>Error</strong> {{ serverError }}
        </ngb-alert>
      </div>
      <div class="mt-5 d-flex justify-content-between">
        <ng-container *ngIf="editPlanData; else create">
          <button
            class="btn"
            [autoClose]="'outside'"
            [ngbPopover]="deletePopoverContent"
            [popoverTitle]="'Delete Plan'"
            #popover="ngbPopover">
            Delete
          </button>
          <ng-template #deletePopoverContent>
            Permanently delete this plan?
            <div class="text-end mt-2">
              <button
                type="button"
                class="btn btn-secondary btn-sm px-3 m-1"
                (click)="popover.close()">
                No
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm px-3 m-1"
                (click)="popover.close(); deletePlan()">
                Yes
              </button>
            </div>
          </ng-template>
          <button
            class="btn btn-primary"
            type="submit"
            (click)="updatePlan()"
            [disabled]="isLoading">
            <span
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm me-1"></span>
            Save
          </button>
        </ng-container>
        <ng-template #create>
          <button class="btn" (click)="createEditPlanModal.close()">
            Cancel
          </button>
          <button
            [disabled]="isLoading"
            class="btn btn-primary"
            type="submit"
            (click)="createPlan()">
            <span
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm me-1"></span>
            Create
          </button>
        </ng-template>
      </div>
    </form>
  </ng-container>
</app-modal>
