<div class="card border-0 mb-4">
  <ng-container *ngIf="reflectionData; else placeHolder">
    <div class="card-header pt-3">
      <h2
        id="evidence-title"
        class="card-title float-start"
        (click)="triggerCollapse()">
        Feedback
        <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
      </h2>
      <div class="card-tools float-end">
        <a
          routerLink="/dashboard/coach-feedback"
          ngbTooltip="See all coach feedback"
          class="text-primary">
          See All
          <i class="ps-1 bi bi-box-arrow-up-right"></i>
        </a>
      </div>
    </div>

    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div class="card-body pt-0 reflection-card" tabindex="0" role="group">
        <ng-container *ngIf="!noFeedbackData; else noFeedback">
          <div class="row">
            <div class="col-sm-6 col-md-12 col-xl-6 gauge-wrapper">
              <div
                echarts
                role="figure"
                class="dashboard-chart"
                [ngStyle]="{ height: '160px' }"
                [options]="chartOptionsConfidence"
                (chartInit)="onChartInit($event)"
                [loading]="isLoading"
                [loadingOpts]="spinnerOptions"></div>
              <div class="gauge-labels d-flex justify-content-between">
                <div class="glabel">Doubtful</div>
                <div class="glabel">Confident</div>
              </div>

              <div class="chart-label fw-800">Confidence</div>
            </div>
            <div class="col-sm-6 col-md-12 col-xl-6 gauge-wrapper">
              <div
                echarts
                role="figure"
                class="dashboard-chart"
                [ngStyle]="{ height: '160px' }"
                [options]="chartOptionsEffectiveness"
                (chartInit)="onChartInit($event)"
                [loading]="isLoading"
                [loadingOpts]="spinnerOptions"></div>
              <div class="gauge-labels d-flex justify-content-between">
                <div class="glabel">Ineffective</div>
                <div class="glabel">Game Changer</div>
              </div>
              <div class="chart-label fw-800">Effectiveness</div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-template #noFeedback>
      <div
        class="text-body-secondary d-flex h-100 w-100 justify-content-center align-items-center">
        No feedback available
      </div>
    </ng-template>
  </ng-container>

  <ng-template #placeHolder>
    <div class="card-header pt-3">
      <div class="card-title placeholder-glow">
        <span class="placeholder col-5"></span>
      </div>
    </div>
    <div class="card-body pt-0" style="height: 18rem">
      <div class="card-text placeholder-glow">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
      <div class="card-text placeholder-glow mt-2">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
    </div>
  </ng-template>
</div>
