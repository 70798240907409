import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import {
  compareRoles,
  UserRole,
} from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { CoachFeedbackDTO } from '../../shared/dtos/feedback.dto';
import { ChartType } from '../../shared/enums/chart-type.enum';
import {
  AlertService,
  AlertType,
} from '../../shared/services/alert/alert.service';
import { CoachFeedbackService } from '../../shared/services/coach-feedback/coach-feedback.service';
import { CURRENT_SCHOOL_YEAR } from '../../shared/services/school-year/school-year.utilities';

@Component({
  selector: 'app-coach-feedback-page',
  templateUrl: './coach-feedback-page.component.html',
  styleUrls: ['./coach-feedback-page.component.scss'],
})
export class CoachFeedbackPageComponent implements OnInit, OnDestroy {
  user: User | null = null;

  selectedUserId: number;

  feedbackItems: CoachFeedbackDTO[];

  listMeta: PaginationDTO;

  chartTypes = ChartType;

  userSearchEnabled = false;

  searchTerm = '';

  perPage = 10;

  private searchSubject: Subject<string> = new Subject<string>();

  disableDownload = false;

  lastUpdatedDate: string;

  subs: Subscription[] = [];

  isLoading = false;

  currentSchoolYearId: number;

  constructor(
    private store: Store,
    private coachFeedbackService: CoachFeedbackService,
    private alertService: AlertService
  ) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
    this.currentSchoolYearId = CURRENT_SCHOOL_YEAR.id;
    this.selectedUserId = this.user.id;

    this.userSearchEnabled = !!compareRoles(
      [UserRole.DISTRICT_ADMIN, UserRole.SCHOOL_ADMIN],
      this.user.roles
    );
  }

  ngOnInit(): void {
    if (this.selectedUserId) {
      this.getCoachFeedback(this.selectedUserId);
    }
    this.searchSubject.pipe(debounceTime(500)).subscribe((searchValue) => {
      this.getCoachFeedback(this.selectedUserId, searchValue);
    });
  }

  getCoachFeedback(userId: number, searchTerm?: string, currentPage = 1) {
    this.isLoading = true;
    if (this.user) {
      this.subs.push(
        this.coachFeedbackService
          .getCoachFeedback(userId, searchTerm || '', currentPage, this.perPage)
          .subscribe((res) => {
            this.feedbackItems = res.items;
            this.listMeta = res._meta;
            this.isLoading = false;
          })
      );
    }
  }

  search(event: Event) {
    this.searchTerm = (event.target as HTMLInputElement).value;
    this.searchSubject.next(this.searchTerm);
  }

  handleUserSearchOutput(event: UserLiteDTO | null) {
    if (event) {
      this.selectedUserId = event.id;
      this.getCoachFeedback(this.selectedUserId);
    }
  }

  downloadCSV() {
    this.disableDownload = true;
    this.subs.push(
      this.coachFeedbackService
        .downloadCoachFeedbackCSV(this.selectedUserId)
        .subscribe({
          error: () => {
            this.disableDownload = false;
          },
          next: (res) => {
            if (res.has_results) {
              // res.file_name should always start with a slash, but need to be sure for the link to work properly
              if (!res.file_name.startsWith('/')) {
                res.file_name = `/${res.file_name}`;
              }
              window.open(
                `${EnvironmentService.apiUrl()}${res.file_name}`,
                '_blank'
              );
            } else {
              this.alertService.showAlert(
                'There was an error downloading feedback',
                undefined,
                AlertType.DANGER
              );
            }
            this.disableDownload = false;
          },
        })
    );
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
