<ul class="nav nav-tabs border-0">
  <li class="nav-item">
    <a
      href="javascript:void()"
      class="nav-link border-0"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active"
      [routerLink]="'/plans/implementation-plan/' + planId + '/dashboard'">
      Plan Dashboard
    </a>
  </li>
  <li class="nav-item">
    <a
      href="javascript:void()"
      class="nav-link border-0"
      routerLinkActive="active"
      [routerLink]="'/plans/implementation-plan/' + planId + '/details'">
      Plan Details
    </a>
  </li>
  <li *ngIf="isPlanEditor" class="nav-item">
    <a
      href="javascript:void()"
      class="nav-link border-0"
      routerLinkActive="active"
      [routerLink]="'/plans/implementation-plan/' + planId + '/sharing'">
      Plan Sharing
    </a>
  </li>
  <li *ngIf="canSeeStatusReports" class="nav-item">
    <a
      href="javascript:void()"
      class="nav-link border-0"
      routerLinkActive="active"
      [routerLink]="'/plans/implementation-plan/' + planId + '/status-reports'">
      Status Reports
    </a>
  </li>
</ul>
