import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ECharts, EChartsOption } from 'echarts';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { CoachingLogService } from '../../../services/coaching-log/coaching-log.service';
import { CardReflectionAPIResponse } from '../../../types/responses/coaching-log.responses';

@Component({
  selector: 'app-card-coach-feedback',
  templateUrl: './card-coach-feedback.component.html',
  styleUrl: './card-coach-feedback.component.scss',
})
export class CardCoachFeedbackComponent implements OnInit {
  user: User;

  isCollapsed = this.cardStorageService.getCollapsedState('coach-feedback');

  eChartsInstance: ECharts;

  chartOptionsConfidence: EChartsOption;

  chartOptionsEffectiveness: EChartsOption;

  isLoading = true;

  noFeedbackData = false;

  spinnerOptions = {
    text: 'Loading...',
    textColor: '#27004b',
    maskColor: 'rgba(255, 255, 255)',
    zlevel: 10,
    fontSize: 24,
    fontFamily: 'greycliff-cf',
    showSpinner: false,
  };

  reflectionData: CardReflectionAPIResponse;

  constructor(
    private cardStorageService: CardStorageService,
    private coachingLogService: CoachingLogService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.getReflectionData();
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'coach-feedback',
      this.isCollapsed
    );
  }

  onChartInit(e: ECharts) {
    this.eChartsInstance = e;
  }

  getReflectionData() {
    this.isLoading = true;
    this.coachingLogService
      .getReflectionData(this.user.id)
      .subscribe((resp) => {
        this.isLoading = false;

        this.reflectionData = resp;

        if (
          this.reflectionData.confidence === 0 &&
          this.reflectionData.effectiveness === 0
        ) {
          this.noFeedbackData = true;
        }

        this.chartOptionsConfidence = {
          grid: {
            show: false,
            top: 0,
            borderWidth: 5,
          },
          tooltip: {
            trigger: 'item',
          },
          series: [
            {
              type: 'gauge',
              startAngle: 180,
              endAngle: 0,
              min: 0,
              max: 4,
              name: 'Confidence',
              splitNumber: 4,
              itemStyle: {
                color: '#267DAD',
              },
              progress: {
                show: true,
                roundCap: false,
                width: 20,
              },
              pointer: {
                show: false,
              },
              axisLine: {
                roundCap: false,
                lineStyle: {
                  width: 20,
                  color: [[1, '#F0EEE9']],
                },
              },
              axisTick: {
                show: true,
                splitNumber: 1,
                lineStyle: {
                  width: 1,
                  color: '#999',
                },
              },
              splitLine: {
                show: false,
                length: 4,
                lineStyle: {
                  width: 3,
                  color: '#999',
                },
              },
              axisLabel: {
                show: false,
                distance: 13,
                color: '#999',
                fontSize: 8,
              },
              title: {
                show: false,
              },
              detail: {
                show: false,
              },
              data: [
                {
                  value: this.reflectionData.confidence,
                },
              ],
            },
          ],
        };

        this.chartOptionsEffectiveness = {
          grid: {
            show: false,
            width: 'auto',
            height: 60,
            top: 0,
          },
          tooltip: {
            trigger: 'item',
          },
          series: [
            {
              type: 'gauge',
              startAngle: 180,
              endAngle: 0,
              min: 0,
              max: 4,
              name: 'Effectiveness',
              splitNumber: 4,
              itemStyle: {
                color: '#AF4F9D',
              },
              progress: {
                show: true,
                roundCap: false,
                width: 20,
              },
              pointer: {
                show: false,
              },
              axisLine: {
                roundCap: false,
                lineStyle: {
                  width: 20,
                  color: [[1, '#F0EEE9']],
                },
              },
              axisTick: {
                show: true,
                splitNumber: 1,
                lineStyle: {
                  width: 1,
                  color: '#999',
                },
              },
              splitLine: {
                show: false,
                length: 4,
                lineStyle: {
                  width: 3,
                  color: '#999',
                },
              },
              axisLabel: {
                show: false,
                distance: 13,
                color: '#999',
                fontSize: 8,
              },
              title: {
                show: false,
              },
              detail: {
                show: false,
              },
              data: [
                {
                  value: this.reflectionData.effectiveness,
                },
              ],
            },
          ],
        };
      });
  }
}
