<div *ngIf="serverError" class="mt-3">
  <div class="container">
    <ngb-alert [dismissible]="false" [type]="'danger'">
      <strong>Error</strong> {{ serverError }}
    </ngb-alert>
  </div>
</div>
<ng-container *ngIf="planData && !isLoading; else loadingTemplate">
  <div
    class="public-wrapper"
    [style.background]="planData.sharing.background_color">
    <div class="container">
      <ng-container *ngIf="true">
        <div class="plan-header">
          <div class="text-center">
            <ng-container *ngIf="planData.sharing.logo">
              <div class="logo mb-3">
                <img
                  class="img-fluid"
                  src="{{ apiUrl }}{{ planData.sharing.logo }}"
                  alt="????" />
              </div>
            </ng-container>
            <h1 class="fw-800" style="font-size: 35px">
              {{ planData.title }}
            </h1>
            <div class="fs-15 mb-3">
              {{ planData.start_date | date : "yyyy" }} -
              {{ planData.end_date | date : "yyyy" }}
            </div>
          </div>
          <div class="fs-16 mb-3">
            <app-safehtml
              [html]="planData.sharing.header_content"></app-safehtml>
          </div>
        </div>

        <ng-container
          *ngIf="planData.phases && planData.phases.length > 0; else noPhases">
          <ng-container *ngFor="let phase of planData.phases">
            <div
              class="phase-wrapper"
              [style.border-color]="planData.sharing.light_color">
              <div
                class="phase-header"
                [style.background]="planData.sharing.light_color">
                <div class="fw-800 fs-22 mb-2">{{ phase.title }}</div>
                <div class="mb-3">{{ phase.description }}</div>

                <div class="progress-wrapper">
                  <div
                    class="progress-bar"
                    [style.background]="planData.sharing.dark_color"
                    [style.width.%]="formatProgress(phase.progress)">
                    <span class="number fw-bold fs-15 text-start ms-2">
                      {{ formatProgress(phase.progress) }}%
                    </span>
                  </div>
                  <div *ngIf="phase.progress === 0" class="zero-percent">
                    0%
                  </div>
                </div>
              </div>
              <div
                *ngIf="planData.sharing.deliverables_enabled"
                class="phase-body">
                <ng-container
                  *ngIf="
                    phase.deliverables && phase.deliverables.length > 0;
                    else noDeliverables
                  ">
                  <ng-container *ngFor="let deliverable of phase.deliverables">
                    <div
                      class="phase-deliverable fs-14 d-flex justify-content-between">
                      <div class="title fw-800">{{ deliverable.title }}</div>
                      <div class="desc ms-4">{{ deliverable.description }}</div>
                      <div class="progress-value ms-4">
                        {{ formatProgress(deliverable.progress) }}%
                      </div>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-template #noDeliverables>
                  <div
                    class="d-flex flex-column align-items-center justify-content-center w-100"
                    style="min-height: 100px">
                    <h4 class="fs-16 fw-600">
                      No
                      {{ planData.deliverable_displayname + "s" }}
                      Found
                    </h4>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #noPhases>
          <div
            *ngIf="planData?.phase_displayname"
            class="d-flex flex-column align-items-center justify-content-center w-100"
            style="min-height: 400px">
            <h2>
              No
              {{ planData.phase_displayname + "s" }}
              Found
            </h2>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-template #loadingTemplate>
  <div *ngIf="!serverError" style="position: relative; height: 100%">
    <div class="w-100" style="background-color: #fff; height: 100%">
      <app-loader></app-loader>
    </div>
  </div>
</ng-template>
