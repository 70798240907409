import { DistrictAPIResponse } from 'src/app/private/shared/types/responses/district-list.responses';
import { State } from '../../utilities/enums/state.enum';
import { DistrictDTO } from '../district.dto';

export const districtDTOFromAPIResponse = (
  district: DistrictAPIResponse
): DistrictDTO => ({
  id: district.id,
  title: district.title,
  isDeleted: district.is_deleted,
  address: district.address,
  address2: district.address2,
  city: district.city,
  state: district.state as State,
  zip: district.zip,
  phone: district.phone,
  domain: district.domain,
  hubspotCompanyId: district.hubspot_company_id,
  districtCode: district.district_code,
  isB2b: district.is_b2b,
  isInternal: district.is_internal,
  tenantId: district.tenantId,
  parentDistrictId: district.parentDistrictId,
  logo: district.logo,
  timezone: district.timezone,
  createdAt: district.created_at,
  updatedAt: district.updated_at,
  counts: {
    users: district.counts?.users || 0,
    schools: district.counts?.schools || 0,
  },
  reportingSchoolYear: district.reporting_school_year,
  defaultPrivateLogs: !!district.default_private_logs,
  users_can_login: district.users_can_login,
  nightlyDataExports: district.nightly_data_exports,
  // TODO: Add schools
});
