import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TodoType } from '../../../enums/todo-type.enum';
import { ToDoDTO } from '../card-todos/todo.dto';

@Component({
  selector: 'app-todo-item',
  templateUrl: './todo-item.component.html',
  styleUrls: ['./todo-item.component.scss'],
})
export class TodoItemComponent implements OnInit {
  @Input() todo: ToDoDTO;

  @Output() readonly checkboxClicked: EventEmitter<unknown> =
    new EventEmitter();

  @Output() readonly editClicked: EventEmitter<unknown> = new EventEmitter();

  isPlanItem = false;

  ngOnInit() {
    this.isPlanItem =
      this.todo.type === TodoType.PLAN_DELIVERABLE ||
      this.todo.type === TodoType.PLAN_ACTION_ITEM;
  }

  handleCheck() {
    this.checkboxClicked.emit();
  }

  handleEdit() {
    this.editClicked.emit();
  }
}
