<app-modal
  #modal
  [modalConfig]="{
    titleText: 'View Status Report',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    showFooter: false,
    options: {
      size: 'lg',
    },
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="modal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <app-safehtml [html]="reportContent" />
</app-modal>
