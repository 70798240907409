<div class="container container-main">
  <app-dashboard-header :dashboardType="coach" />
  <div class="row mb-3">
    <div class="col-md-8">
      <div class="row gy-3">
        <div class="col-md-6">
          <app-card-upcoming-sessions />
        </div>
        <div class="col-md-6">
          <app-card-evidence-for-review />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-recent-celebration />
        </div>
      </div>
      <div class="row row-cols-md-2 gy-3">
        <div class="col-md">
          <app-card-todos />
        </div>
        <div class="col-md">
          <app-card-ideas />
        </div>
        <div class="col-md"></div>
      </div>
    </div>
    <div class="col-md-4">
      <app-card-coach-feedback />
      <app-coachee-list-card />
    </div>
  </div>
</div>
