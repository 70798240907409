<div class="container">
  <app-general-header title="Implementation Plan" />
  <a routerLink="/plans" class="text-decoration-none text-body-secondary"
    >&lt; Back to Plans</a
  >
  <ng-container *ngIf="planDetails">
    <ng-container *ngIf="!planDetails.forbidden; else deniedMessage">
      <div class="row mt-2 mb-4">
        <div *ngIf="planDetails?.title; else noTitle" class="col text-center">
          <div class="d-flex justify-content-center align-items-center">
            <h1 class="fw-600">
              {{ planDetails.title }}
            </h1>
          </div>
          <div>
            {{ planDetails.start_date }} -
            {{ planDetails.end_date }}
          </div>
        </div>
        <ng-template #noTitle>
          <div style="height: 7.5rem"></div>
        </ng-template>
      </div>

      <div class="card bg-none border-0" style="min-height: 700px">
        <app-plans-subnav [planId]="planId"></app-plans-subnav>
        <div class="card-body bg-white dashboard-card">
          <div *ngIf="isPlanEditor" class="row justify-content-end my-3">
            <div
              class="d-flex col-12 col-md-6 order-1 order-md-2 justify-content-center justify-content-md-end my-3 my-md-0">
              <button
                class="btn btn-primary btn-create-report"
                (click)="openCreateReportModal()">
                <i class="bi bi-plus"></i>
                Create a Report
              </button>
            </div>
          </div>
          <div class="table-wrapper w-100">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="report-column">Report</th>
                  <th scope="col" class="creator-column">Report Creator</th>
                  <th scope="col" class="recipients-column">Recipients</th>
                  <th scope="col" class="delete-column"></th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngIf="!statusReportsLoading; else loadingTemplate">
                  <ng-container *ngIf="tableData.length > 0; else noReports">
                    <tr *ngFor="let statusReport of tableData">
                      <td>
                        <a
                          class="link cursor-pointer fw-bold"
                          (click)="openViewReportModal(statusReport.email_body)"
                          >{{ statusReport.date * 1000 | date }}</a
                        >
                      </td>
                      <td>
                        <div class="d-flex flex-nowrap align-items-center my-3">
                          <app-avatar
                            [profile]="statusReport.user.profile"
                            [avatarSize]="'small'">
                          </app-avatar>
                          <div class="username ms-2">
                            {{ statusReport.user.profile.first_name }}
                            {{ statusReport.user.profile.last_name }}
                          </div>
                        </div>
                      </td>
                      <td>
                        {{ statusReport.send_to }}
                      </td>
                      <td class="text-center">
                        <button
                          aria-label="delete status report button"
                          class="btn btn-reset"
                          type="button"
                          [autoClose]="'outside'"
                          [ngbPopover]="contentTemplate"
                          [popoverTitle]="'Delete Status Report'"
                          #popover="ngbPopover">
                          <i class="bi bi-trash color-tertiary"></i>
                        </button>
                        <ng-template #contentTemplate>
                          Are you sure you want to delete this status report?
                          <div class="text-end mt-2">
                            <button
                              type="button"
                              class="btn btn-secondary btn-sm px-3 m-1"
                              (click)="popover.close()">
                              No
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary btn-sm px-3 m-1"
                              (click)="
                                deleteReport(statusReport.id); popover.close()
                              "
                              href="javascript:void(0)">
                              Yes
                            </button>
                          </div>
                        </ng-template>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-template #noReports>
                    <tr>
                      <td colspan="5">
                        <div
                          class="d-flex flex-column align-items-center justify-content-center w-100"
                          style="min-height: 400px">
                          <h2>No Status Reports Found</h2>
                          <p>Please create a status report</p>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </ng-container>
              </tbody>
            </table>
            <section>
              <div class="d-flex justify-content-center">
                <ngb-pagination
                  *ngIf="reportsMeta && reportsMeta.totalCount > 10"
                  [(page)]="reportsMeta.currentPage"
                  [pageSize]="reportsMeta.perPage"
                  [collectionSize]="reportsMeta.totalCount"
                  [maxSize]="5"
                  (pageChange)="paginationChanges($event)"></ngb-pagination>
              </div>
            </section>
          </div>
        </div>
      </div>
    </ng-container>
    <app-create-report-modal
      #createReportModal
      [plan]="planDetails"
      (reportCreated)="updateReports()" />
  </ng-container>
  <app-implementation-view-report-modal
    #viewReportModal
    [reportContent]="reportContent"></app-implementation-view-report-modal>

  <ng-template #loadingTemplate>
    <div class="w-100" style="height: 700px">
      <app-loader></app-loader>
    </div>
  </ng-template>
  <ng-template #deniedMessage>
    <div class="mt-5">
      <app-permission-denied />
    </div>
  </ng-template>
</div>
