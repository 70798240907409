<table class="table">
  <!--<thead>
    <tr>
      <th scope="col" (sort)="onSort($event)" sortable="logTitle">
        Title
        <i class="bi bi-caret-down {{ sortIconTemp.logTitle }}"></i>
      </th>
      <th scope="col" (sort)="onSort($event)" sortable="date">
        Date
        <i class="bi bi-caret-down {{ sortIconTemp.date }}"></i>
      </th>
      <th scope="col" (sort)="onSort($event)" sortable="logType">
        Log Type
        <i class="bi bi-caret-down {{ sortIconTemp.logType }}"></i>
      </th>
      <th
        scope="col"
        (sort)="onSort($event)"
        sortable="coachee"
        class="hide-on-mobile">
        Coachee
        <i class="bi bi-caret-down {{ sortIconTemp.coachee }}"></i>
      </th>
      <th
        scope="col"
        (sort)="onSort($event)"
        sortable="coach"
        class="hide-on-mobile">
        Coach
        <i class="bi bi-caret-down {{ sortIconTemp.coach }}"></i>
      </th>
      <th
        scope="col"
        (sort)="onSort($event)"
        sortable="school"
        class="hide-on-mobile">
        School
        <i class="bi bi-caret-down {{ sortIconTemp.school }}"></i>
      </th>
      <td class="hide-on-mobile"></td>
    </tr>
  </thead>-->
  <thead>
    <tr>
      <th scope="col">Title</th>
      <th scope="col">Date</th>
      <th scope="col">Session Type</th>
      <th scope="col" class="hide-on-mobile">Participant</th>
      <th scope="col" class="hide-on-mobile">Owner</th>
      <th scope="col" class="hide-on-mobile">School</th>
      <td class="hide-on-mobile"></td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let session of tableData">
      <td>
        <a
          target="_blank"
          [routerLink]="getSessionLink(session)"
          class="session-title">
          <b>{{ session.title }}</b>
        </a>
      </td>
      <td>
        <b class="fs-4">{{ session.startDatetime | formatDateMMDDYY }}</b>
      </td>
      <td>
        {{ session.type.title }}
      </td>
      <td class="hide-on-mobile">
        <ng-container *ngFor="let attendee of session.attendees">
          <div class="d-flex align-items-center my-2">
            <app-avatar
              [profile]="attendee.user.profile"
              [avatarSize]="'small'">
            </app-avatar>
            <div class="username ms-2">
              {{ attendee.user.profile.first_name }}
              {{ attendee.user.profile.last_name }}
            </div>
          </div>
        </ng-container>
      </td>
      <td class="hide-on-mobile">
        <div class="d-flex align-items-center my-2">
          <app-avatar [profile]="session.user.profile" [avatarSize]="'small'">
          </app-avatar>
          <div class="username ms-2">
            {{ session.user.profile.first_name }}
            {{ session.user.profile.last_name }}
          </div>
        </div>
      </td>
      <td class="hide-on-mobile">
        <ng-container *ngFor="let attendee of session.attendees">
          <div class="d-flex my-2">
            {{ attendee.user.school?.title }}
          </div>
        </ng-container>
      </td>
      <td class="hide-on-mobile">
        <ng-container *ngIf="session.user.id == user?.id">
          <button
            aria-label="delete session button"
            class="btn btn-reset"
            (click)="deleteSessionModal.open()">
            <i class="bi bi-trash"></i>
          </button>
          <app-modal
            #deleteSessionModal
            [modalConfig]="{
              titleText: 'Are you sure?',
              headerClass: ['modal-header', 'bg-primary', 'text-white'],
              customFooter: true,
            }">
            <button
              class="btn btn-closemodal"
              title="Close"
              aria-label="close"
              headerContent
              (click)="deleteSessionModal.close()">
              <i class="bi bi-x-lg text-white"></i>
            </button>
            <div class="container">
              <div class="row">
                <div
                  class="col-12 d-flex justify-content-center align-items-center">
                  <div class="my-3 fs-14">
                    <div class="fs-18 mb-2">
                      Do you want to permanently delete this session?
                    </div>
                    <ng-container
                      *ngIf="!(session.type.view === 'general-log')">
                      By confirming this action, any growth indicators
                      <ng-container *ngIf="!session.type.isClassroomVisit">
                        and earned badges
                      </ng-container>
                      from this session will be removed.
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex justify-content-between" footerContent>
              <button
                (click)="deleteSessionModal.close()"
                class="btn btn-reset">
                Cancel
              </button>
              <button
                (click)="deleteLog(session); deleteSessionModal.close()"
                class="btn btn-primary">
                Yes, delete session
              </button>
            </div>
          </app-modal>
        </ng-container>
        <ng-container
          *ngIf="
            session.type.isSmart && user?.id == session.attendees[0].userId
          ">
          <button
            (click)="deleteSmartSessionModal.open()"
            aria-label="delete session button"
            class="btn btn-reset">
            <i class="bi bi-trash"></i>
          </button>
          <app-modal
            #deleteSmartSessionModal
            [modalConfig]="{
              titleText: 'Are you sure?',
              headerClass: ['modal-header', 'bg-primary', 'text-white'],
              customFooter: true,
            }">
            <button
              class="btn btn-closemodal"
              title="Close"
              aria-label="close"
              headerContent
              (click)="deleteSmartSessionModal.close()">
              <i class="bi bi-x-lg text-white"></i>
            </button>
            <div class="container">
              <div class="row">
                <div
                  class="col-12 d-flex justify-content-center align-items-center">
                  <div class="my-3 fs-14">
                    <div class="fs-18 mb-2">
                      Do you want to permanently delete this session?
                    </div>
                    By confirming this action, any growth indicators and earned
                    badges from this session will be removed.
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex justify-content-between" footerContent>
              <button
                (click)="deleteSmartSessionModal.close()"
                class="btn btn-reset">
                Cancel
              </button>
              <button
                (click)="
                  deleteCurrentMSCLog(session); deleteSmartSessionModal.close()
                "
                class="btn btn-primary">
                Yes, delete session
              </button>
            </div>
          </app-modal>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>
<section>
  <div class="d-flex justify-content-center">
    <ngb-pagination
      id="coaching-session-pagination"
      aria-label="coaching-session-pagination"
      *ngIf="pagination && pagination.totalCount > 10"
      [(page)]="pagination.currentPage"
      [pageSize]="pagination.perPage"
      [collectionSize]="pagination.totalCount"
      [maxSize]="5"
      (pageChange)="pageChange()" />
  </div>
</section>
