<div class="container container-main">
  <ng-container *ngIf="serverError">
    <ngb-alert [dismissible]="false" [type]="'danger'">
      <strong>Error</strong> {{ serverError }}
    </ngb-alert>
  </ng-container>
  <app-dashboard-header
    *ngIf="user"
    [dashboardType]="user.type"
    [showSwitcher]="false"
    [celebrateFromCoacheeList]="true"
    [selectedCoachees]="attendeeUserProfiles"></app-dashboard-header>
  <div class="card border-0 p-3 mt-3 table-container">
    <div
      class="tool position-absolute top-0 start-100 translate-middle rounded-circle fs-3"
      style="background-color: white; line-height: 1rem">
      <i
        [style.cursor]="'pointer'"
        routerLink="/dashboard"
        ngbTooltip="Close"
        class="bi bi-x-circle color-primary"></i>
    </div>
    <div class="row pb-4">
      <div
        class="col-md-6 col-xl-4 order-3 order-md-2 order-xl-1 justify-content-center justify-content-lg-start">
        <input
          type="search"
          class="form-control ms-1 coachee-search"
          placeholder="Filter coachees"
          aria-label="Search"
          (input)="searchCoachees($event)" />
      </div>
      <div class="col-12 col-xl-4 order-1 order-xl-2 pb-3 text-center">
        <span class="h4">Current school year coachees</span>
      </div>
      <div
        class="col-md-6 col-xl-4 order-2 order-md-3 order-xl-3 d-flex align-items-center justify-content-center justify-content-lg-end py-3 py-md-0">
        <div class="me-3">
          <ng-container
            *ngIf="selectedCoacheesIds.length === 0; else hasSelectedCoachees">
            <app-assign-coachee (coacheeEvent)="coacheesUpdated()" />
          </ng-container>
          <ng-template #hasSelectedCoachees>
            <button
              aria-label="assign-coachee-button"
              type="button"
              class="btn btn-link align-self-center pe-0"
              [autoClose]="'outside'"
              [ngbPopover]="removeCoachees"
              [popoverTitle]="'Unassign Coachees'"
              [autoClose]="'outside'"
              #popover="ngbPopover">
              - Unassign Coachee<span *ngIf="selectedCoacheesIds.length > 1"
                >s</span
              >
            </button>
            <ng-template #removeCoachees>
              Are you sure you want to unassign
              {{ selectedCoacheesIds.length }} user<span
                *ngIf="selectedCoacheesIds.length > 1"
                >s</span
              >
              from yourself?
              <div class="text-end mt-2">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm px-3 m-1"
                  (click)="popover.close()">
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary btn-sm px-3 m-1"
                  (click)="popover.close(); unassignCoachees()">
                  Yes
                </button>
              </div>
            </ng-template>
          </ng-template>
        </div>
        <div>
          <ng-container
            *ngIf="selectedCoacheesIds.length > 9; else defaultButton">
            <button
              container="body"
              type="button"
              class="btn btn-primary mock-disabled"
              ngbTooltip="Maximum number of coachees reached (9). Remove some coachees to re-enable this button">
              <i class="bi bi-plus" style="padding: 0"></i> Create a Session
            </button>
          </ng-container>
          <ng-template #defaultButton>
            <button
              [disabled]="selectedCoacheesIds.length === 0"
              aria-label="Schedule session for coachees"
              type="button"
              container="body"
              ngbTooltip="Schedule session for coachees"
              class="btn btn-primary"
              (click)="createSessionParentModal.openModal()">
              <i class="bi bi-plus" style="padding: 0"></i> Create a Session
            </button>
          </ng-template>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading; else loadingTemplate" class="overflow-auto">
      <table *ngIf="tableData" class="table">
        <thead>
          <tr class="align-middle">
            <th class="text-center" style="width: 150px">
              <span class="visually-hidden">Select</span>
              <!-- <input
                (change)="handleCheckAll()"
                [(ngModel)]="masterSelected"
                type="checkbox"
                class="form-check-input"
                aria-label="Select all coachees" /> -->
            </th>
            <th scope="col" sortable="name" (sort)="onSort($event)">
              Coachees ({{ totalCoachees }})
              <i class="bi bi-caret-down {{ sortIconTemp.name }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="badges"
              class="sortable-header text-center">
              Badges
              <i class="bi bi-caret-down {{ sortIconTemp.badges }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="celebrations"
              class="sortable-header text-center">
              Celebrations
              <i class="bi bi-caret-down {{ sortIconTemp.celebrations }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="sessions"
              class="sortable-header text-center">
              Sessions
              <i class="bi bi-caret-down {{ sortIconTemp.sessions }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="observations"
              class="sortable-header sortable-header-large text-center">
              Observations
              <i class="bi bi-caret-down {{ sortIconTemp.observations }}"></i>
            </th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="nextSession"
              class="sortable-header text-center">
              Next Session
              <i class="bi bi-caret-down {{ sortIconTemp.nextSession }}"></i>
            </th>
            <th class="notes-header">Notes</th>
            <th
              scope="col"
              (sort)="onSort($event)"
              sortable="cohorts"
              class="sortable-header"
              style="width: 20%">
              Cohorts
              <i class="bi bi-caret-down {{ sortIconTemp.cohorts }}"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let coachee of tableData"
            style="border-bottom: 1px solid #78858e5a"
            class="align-middle">
            <td class="text-center">
              <input
                (change)="onCheckboxChange($event)"
                [(ngModel)]="coachee.isSelected"
                class="form-check-input"
                type="checkbox"
                id="{{ 'checkbox' + coachee.id }}"
                value="{{ coachee.id }}"
                aria-label="select" />
            </td>
            <td>
              <label
                for="{{ 'checkbox' + coachee.id }}"
                style="height: 6rem"
                class="d-flex justify-content-start align-items-center">
                <app-avatar
                  class="me-2"
                  [profile]="coachee.profile"
                  [avatarSize]="'medium'">
                </app-avatar>
                {{ coachee.profile.first_name }}
                {{ coachee.profile.last_name }}
              </label>
            </td>
            <td class="text-center">
              <span class="stat-number">{{ coachee.badgeCount }}</span>
            </td>
            <td class="text-center">
              <span class="stat-number">{{ coachee.celebrationsCount }}</span>
            </td>
            <td class="text-center">
              <span class="stat-number">{{ coachee.sessionCount }}</span>
            </td>
            <td class="text-center">
              <span class="stat-number">{{ coachee.observationsCount }}</span>
            </td>
            <td class="text-center">
              <span class="next-session-date">
                <a
                  href="coaching/log/{{ coachee.nextSessionId }}/info"
                  target="_blank"
                  *ngIf="coachee.nextSessionId && coachee.nextSessionDate">
                  {{ coachee.nextSessionDate | formatDateMMDDYY }}
                  <i
                    class="bi bi-box-arrow-up-right ms-2"
                    role="img"
                    aria-label="Go to next session"></i>
                </a>
              </span>
              <span class="text-body-secondary" *ngIf="!coachee.nextSessionId"
                >&mdash;</span
              >
            </td>
            <td class="notes notes-body">
              <app-coachee-notes [coachee]="coachee"></app-coachee-notes>
            </td>
            <td class="align-top">
              <ng-container *ngFor="let cohort of coachee.cohorts">
                <span class="badge badge-item">{{ cohort.tag }}</span
                >&nbsp;
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #loadingTemplate>
      <app-loader></app-loader>
    </ng-template>
  </div>
  <app-create-session-modal
    #createSessionParentModal
    [attendeeUserProfiles]="attendeeUserProfiles" />
</div>
