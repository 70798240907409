<div class="container">
  <app-general-header
    [title]="
      'Manage ' + ((selectedDistrict && selectedDistrict.title) || 'District')
    " />
  <div class="card border-0 p-3">
    <app-settings-nav [districtCode]="districtCode" />
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-8">
          <ng-container *ngIf="!isLoading; else loadingTemplate">
            <form (ngSubmit)="onSubmit()" [formGroup]="form">
              <div class="row mt-3">
                <div class="col position-relative">
                  <div
                    class="bg-neutral color-primary px-2 rounded"
                    id="licenseCount"
                    *ngIf="licenseCount > -1">
                    GroweLab Seats:
                    <strong>{{ formatNumber(licenseCount) }}</strong>
                  </div>
                  <label for="district-name" class="form-label"
                    >District Name</label
                  >
                  <div class="input-group">
                    <input
                      formControlName="districtName"
                      type="text"
                      class="form-control"
                      id="district-name"
                      aria-label="District Name"
                      [ngClass]="{
                        'is-invalid': submitted && f['districtName'].errors
                      }" />
                  </div>
                  <div class="form-text">The full name of the district</div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <label for="district-code" class="form-label"
                    >District Code</label
                  >
                  <div class="input-group">
                    <input
                      formControlName="districtCode"
                      type="text"
                      class="form-control"
                      id="district-code"
                      aria-label="District Code"
                      [ngClass]="{
                        'is-invalid': submitted && f['districtCode'].errors
                      }" />
                  </div>
                  <div class="form-text">
                    A unique text code to identify the district
                  </div>
                </div>
                <div class="col">
                  <div
                    [ngClass]="
                      !user.roles.includes(userRoleDict.E2L_EMPLOYEE)
                        ? 'visually-hidden'
                        : ''
                    ">
                    <label for="crm-id" class="form-label">CRM ID</label>
                    <fieldset disabled>
                      <div class="input-group">
                        <input
                          formControlName="crmId"
                          type="text"
                          class="form-control"
                          id="crm-id"
                          aria-label="CRM ID"
                          [ngClass]="{
                          'is-invalid': submitted && f['crmId'].errors,
                        }" />
                      </div>
                    </fieldset>
                    <div class="form-text">
                      The ID to link the district to the licensing system
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row mb-3">
                <div class="col">
                  <label for="description" class="form-label">Description</label>
                  <fieldset disabled>
                    <div class="input-group">
                      <textarea
                        formControlName="description"
                        class="form-control"
                        id="description"
                        aria-label="Description"></textarea>
                    </div>
                  </fieldset>
                  <div class="form-text">
                    A brief internal description (only viewable to e2L users)
                  </div>
                </div>
              </div> -->
              <div class="row mt-3">
                <div class="col">
                  <label for="address-1" class="form-label">Address 1</label>
                  <div class="input-group">
                    <input
                      formControlName="address1"
                      type="text"
                      class="form-control"
                      id="address-1"
                      aria-label="Address 1" />
                  </div>
                </div>
                <div class="col">
                  <label for="address-2" class="form-label">Address 2</label>
                  <div class="input-group">
                    <input
                      formControlName="address2"
                      type="text"
                      class="form-control"
                      id="address-2"
                      aria-label="Address 2" />
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <label for="city" class="form-label">City</label>
                  <div class="input-group">
                    <input
                      formControlName="city"
                      type="text"
                      class="form-control"
                      id="city"
                      aria-label="City" />
                  </div>
                </div>
                <div class="col">
                  <label for="state" class="form-label">State</label>
                  <div class="input-group">
                    <select
                      formControlName="state"
                      class="form-select"
                      id="state">
                      <option
                        *ngFor="let state of states"
                        [value]="state.value">
                        {{ state.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col">
                  <label for="zip-code" class="form-label">Zip Code</label>
                  <div class="input-group">
                    <input
                      formControlName="zipCode"
                      type="text"
                      class="form-control"
                      id="zip-code"
                      aria-label="Zip Code" />
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <label for="timezone" class="form-label">Timezone</label>
                  <div class="input-group">
                    <select
                      formControlName="timezone"
                      class="form-select"
                      id="timezone">
                      <option
                        *ngFor="let timezone of timezones"
                        [value]="timezone.linkBack">
                        {{ timezone.label }}
                      </option>
                    </select>
                  </div>
                  <div class="form-text">
                    Timezone to localize event display for district users
                  </div>
                </div>
                <div class="col">
                  <label for="domain" class="form-label">Domain</label>
                  <div class="input-group">
                    <input
                      formControlName="domain"
                      type="text"
                      class="form-control"
                      id="domain"
                      aria-label="Domain"
                      [ngClass]="{
                        'is-invalid': submitted && f['domain'].errors
                      }" />
                  </div>
                  <div class="form-text">
                    The domain used to validate district email addresses
                  </div>
                </div>
              </div>
              <ng-container *ngIf="isE2LOperator">
                <div class="row mt-3">
                  <div class="col">
                    <div
                      [ngClass]="
                        !user.roles.includes(userRoleDict.E2L_EMPLOYEE)
                          ? 'visually-hidden'
                          : ''
                      ">
                      <div class="form-check">
                        <input
                          formControlName="isB2b"
                          class="form-check-input"
                          type="checkbox"
                          id="is-b2b" />
                        <label class="form-check-label" for="is-b2b">
                          Is B2B
                        </label>
                      </div>
                      <div class="form-text">
                        Whether this district (e.g. a state) has sub-districts
                        associated with it
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div
                      [ngClass]="
                        !user.roles.includes(userRoleDict.E2L_EMPLOYEE)
                          ? 'visually-hidden'
                          : ''
                      ">
                      <div class="form-check">
                        <input
                          formControlName="isInternal"
                          class="form-check-input"
                          type="checkbox"
                          id="is-internal"
                          checked />
                        <label class="form-check-label" for="is-internal">
                          Is Internal
                        </label>
                      </div>
                      <div class="form-text">
                        Whether this is a test, demo, or similar district for
                        e2L-only user
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <div class="form-check">
                      <input
                        formControlName="usersCanLogin"
                        class="form-check-input"
                        type="checkbox"
                        id="users_can_login" />
                      <label class="form-check-label" for="users_can_login">
                        Users can login
                      </label>
                    </div>
                    <div class="form-text">
                      Does this district have a subscription?
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-check">
                      <input
                        formControlName="dataExports"
                        class="form-check-input"
                        type="checkbox"
                        id="data_exports" />
                      <label class="form-check-label" for="data_exports"
                        >Data Exports</label
                      >
                    </div>
                    <div class="form-text">
                      Should this district receive nightly data exports?
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="errorMessage">
                <div class="alert alert-danger text-start mt-5" role="alert">
                  {{ errorMessage }}
                </div>
              </ng-container>
              <div class="row my-5">
                <div class="col d-flex justify-content-end">
                  <ng-container
                    *ngIf="user.roles.includes(userRoleDict.E2L_EMPLOYEE)">
                    <button
                      aria-label="delete district button"
                      class="btn px-3 mx-3 btn-txt-delete"
                      type="button"
                      [autoClose]="'outside'"
                      [ngbPopover]="deleteOperatorConfirm"
                      [popoverTitle]="'Delete district'"
                      #popover="ngbPopover">
                      Delete
                    </button>
                    <ng-template #deleteOperatorConfirm>
                      Are you sure you want to delete this district?
                      <div class="text-end mt-2">
                        <button
                          type="button"
                          class="btn btn-secondary btn-sm px-3 m-1"
                          (click)="popover.close()">
                          No
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm px-3 m-1"
                          (click)="archiveDistrict(); popover.close()">
                          Yes
                        </button>
                      </div>
                    </ng-template>
                  </ng-container>

                  <button
                    type="button"
                    class="btn btn-secondary px-3 mx-1"
                    (click)="onCancel()">
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary px-3 mx-1"
                    [disabled]="submitLoading">
                    <span
                      *ngIf="submitLoading"
                      class="spinner-border spinner-border-sm me-1"></span>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </ng-container>
          <ng-template #loadingTemplate>
            <app-loader />
          </ng-template>
        </div>
        <div
          class="col-lg-3"
          *ngIf="
            user.roles.includes(Roles.OPERATOR) ||
            user.roles.includes(Roles.REMOTE_OPERATOR)
          ">
          <div class="row my-3">
            <div class="col">
              <div class="mb-3 mt-3">
                <strong
                  >District Operator<span
                    *ngIf="operators && operators.length > 1"
                    >s</span
                  ></strong
                >
              </div>

              <ng-container *ngIf="!operatorsLoading; else loading">
                <ng-container
                  *ngIf="operators && operators.length > 0; else noOperator">
                  <ng-container *ngFor="let operator of operators">
                    <div class="d-flex align-items-center my-3 w-100">
                      <app-avatar
                        [avatarSize]="'small'"
                        class="me-3"
                        [profile]="operator.profile"></app-avatar>
                      <div class="flex-grow-1">
                        {{ operator.profile.first_name }}
                        {{ operator.profile.last_name }}
                      </div>
                      <div>
                        <button
                          aria-label="delete operator button"
                          class="btn btn-delete btn-sm"
                          type="button"
                          [autoClose]="'outside'"
                          ngbTooltip="Delete Operator"
                          [ngbPopover]="deleteOperatorTemplate"
                          [popoverTitle]="'Delete Operator'"
                          #popover="ngbPopover">
                          <i class="bi bi-trash"></i>
                        </button>
                        <ng-template #deleteOperatorTemplate>
                          Are you sure you want remove this user as an operator?
                          <div class="text-end mt-2">
                            <button
                              type="button"
                              class="btn btn-secondary btn-sm px-3 m-1"
                              (click)="popover.close()">
                              No
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary btn-sm px-3 m-1"
                              (click)="
                                removeOperator(operator.id); popover.close()
                              ">
                              Yes
                            </button>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #noOperator>
                  <div class="py-5">
                    Operators have not been assigned to this district.
                  </div>
                </ng-template>
              </ng-container>
              <ng-template #loading>
                <div class="py-5">loading operators</div>
              </ng-template>

              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-primary my-3"
                  (click)="selectOperatorModal.open()">
                  Select Operator
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal
  #selectOperatorModal
  [ngClass]="['mw800']"
  hiddenModal="closeModal"
  [modalConfig]="{
    titleText: 'Select Operator',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    showFooter: false
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="selectOperatorModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div class="my-4">
    Be sure users have been added to your district first
    <app-user-select
      *ngIf="selectedDistrict"
      [districtId]="selectedDistrict.id"
      [clearAfterSelection]="true"
      (selectedUser)="selectOperator($event)" />
  </div>
</app-modal>
