<ng-container *ngIf="user">
  <div
    *ngIf="canCreateLog"
    ngbTooltip="Create Quick Walk Session"
    placement="top"
    [style.display]="'inline-block'"
    [style.margin-right]="'1rem'">
    <button
      aria-label="quick-walk-session-icon"
      placement="bottom-end"
      class="icon btn-reset"
      (click)="openQuickWalkModal()"
      [ngStyle]="{
        'background-image': 'url(/assets/report/observations.svg)'
      }"></button>
  </div>
  <div
    ngbTooltip="Throw Confetti"
    placement="top"
    [style.display]="'inline-block'"
    [style.margin-right]="'1rem'">
    <button
      aria-label="dashboard-celebration-icon"
      placement="bottom-end"
      class="icon btn-reset"
      (click)="cueConfetti()"
      [ngStyle]="{
        'background-image': 'url(/assets/confetti.svg)'
      }"></button>
  </div>
  <div
    placement="top"
    ngbTooltip="Celebrations"
    [style.display]="'inline-block'">
    <button
      aria-label="celebrations-header-icon"
      [autoClose]="'outside'"
      [ngbPopover]="celebrationsPopover"
      popoverClass="popover-lg"
      placement="bottom-end"
      class="icon btn-reset"
      #celebrationPopover="ngbPopover"
      [ngStyle]="{
        'background-image': 'url(/assets/celebrations.svg)'
      }"></button>
  </div>

  <ng-template #celebrationsPopover>
    <app-card-celebrations-feed
      (openCelebrationModalEvent)="openCelebrationModal()"
      (createCelebrationEvent)="handleCreateCelebrationEvent()"
      [celebrations]="celebrations"
      [user]="user" />
  </ng-template>
  <app-create-edit-celebration-modal
    (createCelebrationEvent)="handleCreateCelebrationEvent()"
    [user]="user"
    #celebrationModal />
  <app-create-quick-walk-modal #createQuickWalkModal />
</ng-container>
