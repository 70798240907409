import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import {
  compareRoles,
  UserRole,
} from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  CelebrationDTO,
  CelebrationFilterDTO,
} from '../../dtos/celebrations.dto';
import { CelebrationsService } from '../../services/celebrations/celebrations.service';
import { CoachingLogPusherService } from '../../services/coaching-log/coaching-log-pusher.service';
import { ConfettiService } from '../../services/confetti/confetti.service';
import { CreateQuickWalkModalComponent } from '../create-quick-walk-modal/create-quick-walk-modal.component';
import { CreateEditCelebrationModalComponent } from '../modals/create-edit-celebration-modal/create-edit-celebration-modal.component';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrl: './action-buttons.component.scss',
})
export class ActionButtonsComponent implements OnInit {
  @ViewChild('celebrationModal')
  celebrationModal: CreateEditCelebrationModalComponent;

  @ViewChild('celebrationPopover') celebrationPopover: NgbPopover;

  @ViewChild('createQuickWalkModal')
  createQuickWalkModal: CreateQuickWalkModalComponent;

  celebrations: CelebrationDTO[] | null = null;

  celebrationFilters: CelebrationFilterDTO = {
    fromDate: null,
    toDate: null,
    hasAttachment: 0,
    school: null,
    district: null,
    ownOnly: 1,
    coacheeList: [],
  };

  user: User;

  canCreateLog = false;

  constructor(
    private router: Router,
    private celebrationService: CelebrationsService,
    private coachingLogPusherService: CoachingLogPusherService,
    private confettiService: ConfettiService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.getCelebrations();

    this.coachingLogPusherService.confettiNotifier.subscribe(() => {
      this.confettiService.showConfetti('top');
    });

    if (this.user) {
      this.canCreateLog = compareRoles([UserRole.COACH], this.user.roles);
    }
  }

  openCelebrationModal() {
    this.celebrationModal.openModal();
    this.celebrationPopover.close();
  }

  openQuickWalkModal() {
    this.createQuickWalkModal.openModal();
  }

  handleCreateCelebrationEvent() {
    this.getCelebrations();
  }

  getCelebrations() {
    this.celebrationFilters.perPage = 5;
    this.celebrationService
      .getFilteredCelebrations(this.celebrationFilters)
      .subscribe((res) => {
        this.celebrations = res.items;
        this.celebrations.reverse();
      });
  }

  cueConfetti() {
    if (this.router.url.match('^/coaching/log/[0-9]{1,12}')) {
      let logId = 0;
      const routeParams = this.router.url.split('/');
      if (routeParams.length > 3) {
        logId = parseInt(routeParams[3], 10);
      }

      const channel = this.coachingLogPusherService.getChannel(
        `coachlog-v2-${logId}`
      );
      this.confettiService.showConfetti('top');
      channel.trigger('client-celebration:cueConfetti', { success: true });
    } else {
      this.confettiService.showConfetti('top');
    }
  }
}
