<div class="container">
  <app-general-header
    [title]="
      'Manage ' + ((selectedDistrict && selectedDistrict.title) || 'District')
    " />
  <div class="card border-0 p-3">
    <app-settings-nav [districtCode]="districtCode" />
    <ng-container *ngIf="!isPageLoading; else loadingContent">
      <form [formGroup]="configurationForm" (ngSubmit)="onSubmit()">
        <div class="container mt-3">
          <h4>Reporting Goals</h4>

          <div class="overflow-auto">
            <table *ngIf="tableData" class="table">
              <thead class="table-header">
                <tr>
                  <th colspan="2"></th>
                  <th colspan="12" class="text-center">Monthly Benchmark</th>
                </tr>
                <tr>
                  <th>Success Metric</th>
                  <th style="min-width: 110px">Annual Goal</th>
                  <th>July</th>
                  <th>Aug</th>
                  <th>Sept</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>June</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colspan="14" class="text-start fs-18 color-level-2">
                    Inputs
                  </th>
                </tr>
                <ng-container *ngFor="let districtGoal of tableData">
                  <tr *ngIf="districtGoal.goal.type === GoalType.INPUT">
                    <td>
                      <span class="fw-bold"
                        >{{ districtGoal.goal.title }}:</span
                      >
                      {{ districtGoal.goal.description }}
                    </td>
                    <ng-container *ngFor="let month of months">
                      <td>
                        <input
                          [formControlName]="
                            getControlName(districtGoal.goal.id, month.name)
                          "
                          type="number"
                          class="form-control goal-input"
                          [ngClass]="{
                            'is-invalid':
                              f[
                                getControlName(districtGoal.goal.id, month.name)
                              ].errors
                          }" />
                        <div
                          *ngIf="
                            f[getControlName(districtGoal.goal.id, month.name)]
                              .invalid &&
                            (f[getControlName(districtGoal.goal.id, month.name)]
                              .touched ||
                              f[
                                getControlName(districtGoal.goal.id, month.name)
                              ].dirty)
                          "
                          class="text-danger fs-12 mt-1 error-message">
                          <div
                            *ngIf="f[getControlName(districtGoal.goal.id, month.name)].errors?.['required']">
                            Required
                          </div>
                          <div
                            *ngIf="f[getControlName(districtGoal.goal.id, month.name)].errors?.['negative']">
                            Cannot be negative
                          </div>
                        </div>
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>

                <tr>
                  <th colspan="14" class="text-start fs-18 color-level-2">
                    Outcomes
                  </th>
                </tr>
                <ng-container *ngFor="let districtGoal of tableData">
                  <tr *ngIf="districtGoal.goal.type === GoalType.OUTCOME">
                    <td>
                      <span class="fw-bold"
                        >{{ districtGoal.goal.title }}:</span
                      >
                      {{ districtGoal.goal.description }}
                    </td>
                    <ng-container *ngFor="let month of months">
                      <td>
                        <input
                          [formControlName]="
                            getControlName(districtGoal.goal.id, month.name)
                          "
                          type="number"
                          class="form-control goal-input"
                          [ngClass]="{
                            'is-invalid':
                              f[
                                getControlName(districtGoal.goal.id, month.name)
                              ].errors
                          }" />
                        <div
                          *ngIf="
                            f[getControlName(districtGoal.goal.id, month.name)]
                              .invalid &&
                            (f[getControlName(districtGoal.goal.id, month.name)]
                              .touched ||
                              f[
                                getControlName(districtGoal.goal.id, month.name)
                              ].dirty)
                          "
                          class="text-danger fs-13 mt-1">
                          <div
                            *ngIf="f[getControlName(districtGoal.goal.id, month.name)].errors?.['required']">
                            Required
                          </div>
                          <div
                            *ngIf="f[getControlName(districtGoal.goal.id, month.name)].errors?.['negative']">
                            Cannot be negative
                          </div>
                        </div>
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <div class="container mt-4">
          <h4>Reporting Year Default</h4>
          <div class="row d-flex align-items-end">
            <div class="col-lg-4">
              <label for="reportedSchoolYear" class="form-label"
                >School Year:</label
              >
              <app-year-select
                (selectedYear)="onYearSelected($event)"
                [defaultYear]="
                  this.selectedDistrict?.reportingSchoolYear || null
                " />
            </div>
          </div>
        </div>
        <div class="container mt-4">
          <h4>Session Privacy</h4>
          <div class="row d-flex align-items-end">
            <div class="col-lg-12">
              <div class="form-check d-flex align-items-center">
                <input
                  class="form-check-input mt-2"
                  type="checkbox"
                  formControlName="selectedDefaultPrivateLogs" />
                <label for="isPrivateSession" class="form-label ms-2"
                  >Private Sessions</label
                >
              </div>
              <div class="form-check-label ms-4">
                By default, sessions will be private. This means that the
                session can only be viewed by the coach, coachee(s), and
                shadower of the session.
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="emailSettingsEnabled">
          <div class="container mt-5">
            <h4>Email Notifications</h4>
            <div class="row d-flex align-items-end">
              <p>
                Select which email notifications will be sent to users in the
                entire district.
              </p>
            </div>
            <div class="row d-flex align-items-start">
              <div class="col-lg-10">
                <div class="row">
                  <div
                    class="col-12 col-sm-6 col-lg-4 mb-2"
                    *ngFor="let email of emailNotifications">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        (change)="onCheckboxChange($event)"
                        [ngModel]="email.isEnabled"
                        [ngModelOptions]="{ standalone: true }"
                        [id]="email.id" />
                      <label class="form-check-label" [for]="email.id">
                        {{ email.title }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="row d-flex justify-content-center my-5">
          <div class="col-11 d-flex justify-content-end">
            <button
              type="submit"
              class="btn btn-primary px-3"
              [disabled]="
                !configurationForm.valid ||
                (!configurationForm.dirty &&
                  notificationsUpdated.length == 0 &&
                  yearSelected == false) ||
                submitLoading
              ">
              <span
                *ngIf="submitLoading"
                class="spinner-border spinner-border-sm me-1"></span>
              Save
            </button>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-template #loadingContent>
      <div class="app-loader-container" style="height: 400px">
        <app-loader></app-loader>
      </div>
    </ng-template>
  </div>
</div>
