<div class="container container-main">
  <app-general-header
    [title]="
      ((selectedSchool && selectedSchool.title) ||
        (selectedDistrict && selectedDistrict.title) ||
        'District') + ' Reporting'
    " />
  <div class="card border-0 p-3 mb-3">
    <app-reporting-subnav></app-reporting-subnav>
    <div class="card-body">
      <div>
        <ngb-alert
          *ngIf="noResults"
          [type]="'danger'"
          (closed)="closeErrorMsg()">
          Something went wrong generating your report download.
        </ngb-alert>
      </div>
      <div class="d-flex justify-content-end">
        <button
          ngbTooltip="Download CSV"
          aria-label="Download CSV"
          class="btn"
          container="body"
          placement="bottom"
          [autoClose]="false"
          [ngbPopover]="csvDownloadingPopover"
          [disabled]="disableDownload"
          (click)="downloadCSV()"
          #p="ngbPopover">
          <i [ngClass]="['bi', 'fs-21', 'bi-download']"></i>
        </button>
        <ng-template #csvDownloadingPopover>
          <div class="p-2">
            Your report is generating. The download will begin shortly.
          </div>
          <div class="p-2 d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              (click)="p.close()">
              OK
            </button>
          </div>
        </ng-template>
      </div>
      <div class="p-3">
        <app-report-filter-bar
          [reportName]="'evidence_report'"
          [canFilterByDistrict]="canFilterByDistrict"></app-report-filter-bar>
      </div>
      <div id="evidence-report-area" class="position-relative">
        <h3 class="fs-5 fw-800 mt-5 text-center"># of Evidence Artifacts</h3>
        <app-report-controller
          #evidenceArtifactsReport
          chartId="evidence-chart"
          cardHeight="50rem"
          [chartType]="[chartTypes.HorizontalStackedBar]"
          [requestParams]="evidenceParams"
          [drilldownDimensions]="['user_name', 'standard_name']"
          [seriesSort]="'numeric'"
          [replaceChartOptions]="true"
          [tooltipTrigger]="'item'"
          [tooltipFormatter]="toolTipFormatter"
          [colorPalette]="[
            '#F68888',
            '#CD87C0',
            '#6FADCF',
            '#EDA9CB',
            '#a68dbe'
          ]"
          [gridDimensions]="{
            top: '30px',
            left: '23px',
            right: '15px',
            bottom: '60px',
          }"
          [legendOptions]="{
            show: true,
            bottom: 0,
            icon: 'circle',
            formatter: 'Level {name}',
            textStyle: {
              fontFamily: 'greycliff-cf'
            }
          }" />
      </div>
      <div class="mt-5 text-muted small text-end">
        Data last refreshed at {{ lastUpdatedDate }}
      </div>
    </div>
  </div>
</div>
