import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { PreviewCompetencyComponent } from '../../shared/components/competency/preview-competency-modal/preview-competency.component';
import { TopicDTO } from '../../shared/components/topic-select/topic-select.component';
import {
  SortEvent,
  SortableHeader,
} from '../../shared/directives/sortable-header.directive';
import {
  CompetencyDTO,
  CompetencyRequestDTO,
} from '../../shared/dtos/competencies.dto';
import { DistrictListService } from '../../shared/services/district-list/district-list-service';
import {
  CompetencyFilterParams,
  TopicBuilderService,
} from '../../shared/services/topic-builder/topic-builder.service';

@Component({
  selector: 'app-coaching-competencies-page',
  templateUrl: './coaching-competencies-page.component.html',
  styleUrl: './coaching-competencies-page.component.scss',
})
export class CoachingCompetenciesPageComponent implements OnInit {
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  @ViewChild('competencyPreviewModal')
  competencyPreviewModal: PreviewCompetencyComponent;

  user: User;

  competencies: CompetencyDTO[];

  districtCode: string;

  district: DistrictDTO;

  topics: TopicDTO[] = [];

  loadingIcons = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortIconTemp: any = {
    title: 'gray',
    topic: 'gray',
    is_smart: 'gray',
    is_visible: 'gray',
  };

  sortInfo = {
    column: '',
    direction: '',
  };

  filterParams = {} as CompetencyFilterParams;

  filterTopicIds: number[] = [];

  previewData: CompetencyRequestDTO;

  constructor(
    private store: Store,
    private topicBuilderService: TopicBuilderService,
    private route: ActivatedRoute,
    private districtList: DistrictListService
  ) {}

  ngOnInit() {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;
    this.districtList
      .fetchDistrictsSimple({ per_page: 1000 })
      .subscribe((districts) => {
        if (districts) {
          const district = districts.find(
            (searchDistrict) =>
              searchDistrict.districtCode === this.districtCode
          ) as DistrictDTO;
          if (district) {
            this.district = district;
            this.getCompetencies();
            this.getTopics();
          }
        }
      });
  }

  getCompetencies() {
    this.topicBuilderService
      .getDistrictCompetencies(
        this.district.id,
        this.filterParams,
        this.filterTopicIds
      )
      .subscribe({
        next: (response) => {
          this.competencies = response.items;
          this.updateColumns();
        },
      });
  }

  toggleCompetency(competency: CompetencyDTO) {
    if (this.district && competency.rubric) {
      if (competency.is_visible && this.district) {
        this.topicBuilderService
          .turnCompetencyOff(this.district.id, competency.id)
          .subscribe((response) => {
            if (response) {
              this.getCompetencies();
            }
          });
      } else {
        this.topicBuilderService
          .turnCompetencyOn(
            this.district.id,
            competency.id,
            competency.rubric.id
          )
          .subscribe((response) => {
            if (response) {
              this.getCompetencies();
            }
          });
      }
    }
  }

  getTopics() {
    if (this.user) {
      this.topicBuilderService
        .getDistrictRubrics(this.district.id)
        .subscribe((res) => {
          res.items.forEach((item: { rubric: TopicDTO }) => {
            if (item.rubric.is_calibration === 0) {
              this.topics.push(item.rubric);
            }
          });
        });
    }
  }

  filterByTopic(topic: TopicDTO) {
    this.filterTopicIds = [];
    if (topic) {
      this.filterTopicIds.push(topic.id);
    }
    this.getCompetencies();
  }

  onSort({ column, direction }: SortEvent) {
    if (direction === '') {
      this.filterParams = {} as CompetencyFilterParams;
    } else {
      this.filterParams.sort_order = column;
      if (direction === 'asc') {
        this.filterParams.sort_dir = 'ASC';
      } else if (direction === 'desc') {
        this.filterParams.sort_dir = 'DESC';
      }
    }
    this.sortInfo.column = column;
    this.sortInfo.direction = direction;

    this.loadingIcons = true;
    this.getCompetencies();
  }

  updateColumns() {
    this.headers.forEach((header) => {
      if (header.sortable !== this.sortInfo.column) {
        header.direction = '';
        this.sortIconTemp[header.sortable] = 'gray';
      } else {
        this.sortIconTemp[header.sortable] = 'none';
      }
    });

    if (this.sortInfo.direction === '' || this.sortInfo.column === '') {
      this.sortIconTemp[this.sortInfo.column] = 'gray';
    }
    this.loadingIcons = false;
  }

  previewCompetency(competency: CompetencyDTO) {
    if (competency.rubric) {
      this.previewData = {} as CompetencyRequestDTO;
      this.previewData.standardId = competency.id;
      this.previewData.rubricId = competency.rubric.id;
    }
    this.competencyPreviewModal.openModal();
  }
}
