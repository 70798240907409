import { Injectable } from '@angular/core';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';

@Injectable({
  providedIn: 'root',
})
export class PlansSortingService {
  constructor(private apiService: APICoreService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, class-methods-use-this
  calcSortChange(sortItems: any[], oldIndex: number, newIndex: number) {
    if (oldIndex === newIndex) {
      return null;
    }

    let direction = 'after';

    // get the ID of the item at the oldIndex (this is what we are moving)
    const itemId = sortItems[oldIndex].id;
    // get the ID of the item at the newIndex (this is where we are moving it to)
    const refId = sortItems[newIndex].id;

    if (oldIndex > newIndex) {
      direction = 'before';
    }

    return { id: itemId, direction, refId };
  }

  sortPhase(phaseId: number, direction: string, refId: number) {
    return this.apiService.putRequest(
      `growelab/implementation-plan/phase/${phaseId}/sort/${direction}/${refId}`
    );
  }

  sortDeliverable(delId: number, direction: string, refId: number) {
    return this.apiService.putRequest(
      `growelab/implementation-plan/deliverable/${delId}/sort/${direction}/${refId}`
    );
  }

  sortActionItem(actionItemId: number, direction: string, refId: number) {
    return this.apiService.putRequest(
      `growelab/implementation-plan/action-item/${actionItemId}/sort/${direction}/${refId}`
    );
  }
}
