<div class="container all-celebrations-container">
  <app-dashboard-header
    *ngIf="user"
    [showSwitcher]="false"></app-dashboard-header>
  <div class="card border-0 p-3">
    <div
      class="tool position-absolute top-0 start-100 translate-middle rounded-circle fs-3"
      style="background-color: white; line-height: 1rem">
      <i
        [style.cursor]="'pointer'"
        routerLink="/dashboard"
        ngbTooltip="Close"
        class="bi bi-x-circle color-primary"></i>
    </div>
    <div class="card-body">
      <div *ngIf="userSearchEnabled" class="w-25 mb-4">
        <app-user-select
          (selectedUser)="handleUserSearchOutput($event)"
          [defaultUsers]="user ? [user] : null" />
      </div>
      <div *ngIf="user && user.district" class="row mb-4">
        <div class="col-lg-6">
          <div class="fs-5 fw-800 mb-1">Confidence</div>
          <div class="position-relative">
            <app-report-controller
              [chartColor]="['#267dad']"
              [chartType]="[chartTypes.Bar]"
              [yNameGap]="35"
              [yMax]="4"
              [isSqlRequest]="true"
              [sqlRequestParams]="{
                report: 'coachConfidence',
                filters: [
                  {
                    codename: 'school_year',
                    value: [currentSchoolYearId.toString()],
                  },
                  {
                    codename: 'user',
                    value: [selectedUserId.toString()],
                  },
                ],
              }"></app-report-controller>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="fs-5 fw-800 mb-1">Effectiveness</div>
          <div class="position-relative">
            <app-report-controller
              [chartColor]="['#af4f9d']"
              [chartType]="[chartTypes.Bar]"
              [yNameGap]="35"
              [yMax]="4"
              [isSqlRequest]="true"
              [sqlRequestParams]="{
                report: 'coachEffectiveness',
                filters: [
                  {
                    codename: 'school_year',
                    value: [currentSchoolYearId.toString()],
                  },
                  {
                    codename: 'user',
                    value: [selectedUserId.toString()],
                  },
                ],
              }"></app-report-controller>
          </div>
        </div>
      </div>
      <div class="fs-5 fw-800 mb-3">Feedback</div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <div class="w-25">
          <input
            type="search"
            class="form-control"
            placeholder="Search Feedback"
            aria-label="Search"
            (input)="search($event)" />
        </div>
        <div
          class="d-flex align-items-center"
          *ngIf="feedbackItems && feedbackItems.length > 0">
          <button
            class="btn"
            aria-label="Download CSV of All Feedback"
            ngbTooltip="Download CSV of All Feedback"
            container="body"
            placement="bottom"
            [autoClose]="false"
            [ngbPopover]="downloadConfirmationPopover"
            [disabled]="disableDownload"
            (click)="downloadCSV()"
            #downloadCSVPopover="ngbPopover">
            <i class="bi fs-21 bi-download"></i>
          </button>
          <ng-template #downloadConfirmationPopover>
            <div class="p-2">
              Your CSV is generating. The download will begin shortly.
            </div>
            <div class="p-2 d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                (click)="downloadCSVPopover.close()">
                OK
              </button>
            </div>
          </ng-template>
        </div>
      </div>
      <ng-container *ngIf="!isLoading && feedbackItems; else loadingTemplate">
        <div *ngFor="let feedback of feedbackItems" class="feedback-item">
          {{ feedback.content }}
        </div>
        <ng-container *ngIf="feedbackItems.length < 1">
          <div
            class="d-flex flex-column justify-content-center align-items-center"
            style="min-height: 500px">
            <h2>No Results Found</h2>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div
      *ngIf="listMeta && listMeta.totalCount > listMeta.perPage"
      class="row mt-4">
      <div class="d-flex justify-content-center">
        <ngb-pagination
          [(page)]="listMeta.currentPage"
          [pageSize]="listMeta.perPage"
          [collectionSize]="listMeta.totalCount"
          (pageChange)="
            getCoachFeedback(selectedUserId, searchTerm, listMeta.currentPage)
          "
          [maxSize]="5"
          [ellipses]="true"></ngb-pagination>
      </div>
    </div>

    <ng-template #loadingTemplate>
      <div
        class="w-100 position-relative"
        style="background-color: #fff; height: 500px">
        <app-loader></app-loader>
      </div>
    </ng-template>
  </div>
</div>
